import React from "react";

import LoginForm from "./login/components/LoginForm";
import { PageWithFullScreenBackgroundImage } from "../../components/PageWithFullScreenBackground";
import { Card } from "../../components/card";

function Login() {
  return (
    <PageWithFullScreenBackgroundImage>
      <Card>
        <LoginForm />
      </Card>
    </PageWithFullScreenBackgroundImage>
  );
}

export default Login;
