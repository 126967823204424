import { defineMessages } from "react-intl";

export const messages = defineMessages({
  registrationCodeInvalid: {
    id: "register.registration_code.invalid",
    defaultMessage: "Your registration code is invalid",
    description: "Register form - error - invalid registration code",
  },
  registrationCodeRequired: {
    id: "register.registration_code.required",
    defaultMessage: "Registration code is required",
    description: "Register form - error - required registration code",
  },
});
