import { LinkedAccountsHeader } from "./LinkedAccountsHeader";
import { SteamLinkedAccount } from "./steam/SteamLinkedAccount";

export const LinkedAccounts = () => {
  return (
    <div>
      <LinkedAccountsHeader />
      <div className="py-5 px-5">
        <SteamLinkedAccount />
      </div>
    </div>
  );
};
