import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

import rampyErrorImg from "../assets/images/rampyError.png";
import rampyErrorWebp from "../assets/images/rampyError.webp";

function NotFound() {
  return (
    <motion.main
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="bg-zinc-900"
    >
      <div className="flex flex-col w-full min-h-screen">
        <main className="flex-grow flex flex-col justify-center max-w-3xl w-full mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex-shrink-0 flex justify-center">
            <Link to="/" className="inline-flex">
              <span className="sr-only">Rampy the Squirrel</span>
              <picture>
                <source srcSet={rampyErrorWebp} type="image/webp" />
                <img
                  src={rampyErrorImg}
                  alt="A confused Rampy the Squirrel due to the 404 error."
                  className="aspect-auto w-64 h-64 brightness-90"
                />
              </picture>
            </Link>
          </div>
          <div className="py-16">
            <div className="text-center">
              <p className="text-3xl font-semibold text-[#8ce8e8]">404</p>
              <h1 className="mt-2 text-4xl font-bold text-zinc-300 tracking-tight sm:text-5xl sm:tracking-tight">
                Page not found.
              </h1>
              <p className="mt-4 text-base text-zinc-400">
                Sorry, Rampy was confused and unable to find the page you’re
                looking for.
              </p>
              <div className="mt-6">
                <Link
                  to="/"
                  className="text-base font-medium text-white hover:text-[#8ce8e8]"
                >
                  Go back home<span aria-hidden="true"> &rarr;</span>
                </Link>
              </div>
            </div>
          </div>
        </main>
      </div>
    </motion.main>
  );
}

export default NotFound;
