import { useNavigate } from "react-router-dom";
import { routes } from "../routes/routes";

export const useRedirection = () => {
  const navigate = useNavigate();

  return {
    redirectToLogin: () => {
      navigate(routes.LOGIN, { replace: true });
    },
    redirectToAccount: () => {
      navigate(routes.ACCOUNT, { replace: true });
    },
  };
};
