import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import { AnimatePresence } from "framer-motion";

import Layout from "./Layout";
import Main from "../pages/Main";
import NotFound from "../pages/NotFound";

import News from "../pages/News";
import NewsArticle from "../pages/NewsArticle";

import PolicyMain from "../pages/Policy/PolicyMain";
import PrivacyPolicy from "../pages/Policy/PrivacyPolicy";
import TermsAndConditions from "../pages/Policy/TermsAndConditions";
import DiscordRules from "../pages/Policy/DiscordRules";

// account authentication
import ForgotPassword from "../pages/Account/forgot-password/ForgotPassword";
import AccountMain from "../pages/Account/AccountMain";
import Register from "../pages/Account/Register";
import Login from "../pages/Account/Login";
import { AuthGuard } from "../auth/AuthGuard";
import { GuestGuard } from "../auth";

// account dashboard
import GeneralDetails from "../pages/Account/dashboard/components/general-details/GeneralDetails";
import Entitlements from "../pages/Account/dashboard/components/entitlements/Entitlements";
import BillPortal from "../pages/Account/dashboard/components/billing-portal/BillPortal";
import DonationHistory from "../pages/Account/dashboard/components/donation-history/DonationHistory";
import ResetPassword from "../pages/Account/reset-password/ResetPassword";
import { DiscordConnexion } from "../pages/Account/dashboard/components/discord-account-link/DiscordConnexion";
import { DiscordAccountLink } from "../pages/Account/dashboard/components/discord-account-link/DiscordAccountLink";
import { VerifyEmail } from "../pages/VerifyEmail";
import { LinkedAccounts } from "../pages/Account/dashboard/components/general-details/LinkedAccounts";
import { GamesparksDonationLinkConfirm } from "../pages/Donation";

function AnimatedRoutes() {
  const location = useLocation();

  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<Layout />}>
          <Route index element={<Main />} />

          <Route path="/news" element={<News />} />
          <Route path="/news/:articleSlug" element={<NewsArticle />} />

          <Route element={<GuestGuard />}>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
          </Route>

          <Route path="reset-password" element={<ResetPassword />} />

          <Route path="/policy" element={<PolicyMain />} />
          <Route path="/policy/privacy-policy" element={<PrivacyPolicy />} />
          <Route
            path="/policy/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          <Route path="/policy/discord-rules" element={<DiscordRules />} />
          <Route path="verify-account" element={<VerifyEmail />} />

          <Route
            path="/donation-link"
            element={<GamesparksDonationLinkConfirm />}
          />

          {/* Pages that need authentication */}
          <Route element={<AuthGuard />}>
            <Route path="/account" element={<AccountMain />}>
              {/* Nested routes for account dashboard */}
              <Route path="" element={<GeneralDetails />} />
              <Route path="general-details" element={<GeneralDetails />} />
              <Route path="entitlements" element={<Entitlements />} />
              <Route path="billing-portal" element={<BillPortal />} />
              <Route path="donation-history" element={<DonationHistory />} />
              <Route path="discord-account" element={<DiscordAccountLink />} />
              <Route
                path="discord-account/register/:id"
                element={<DiscordConnexion />}
              />
              <Route path="linked-accounts" element={<LinkedAccounts />} />
            </Route>
          </Route>

          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </AnimatePresence>
  );
}

export default AnimatedRoutes;
