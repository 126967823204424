import { defineMessages } from "react-intl";

export const messages = defineMessages({
  invalidToken: {
    id: "donation.gamesparks-donation-link-confirm.invalid-token",
    defaultMessage: "The link you followed is incorrect or expired",
    description: "Gamesparks donation link confirm - invalid token",
  },
  success: {
    id: "donation.gamesparks-donation-link-confirm.success",
    defaultMessage: "Your gamesparks account has been successfully imported",
    description: "Gamesparks donation link confirm - success",
  },
});
