import React from "react";
import { Formik, Form } from "formik";
import { Link } from "react-router-dom";
import { Input } from "../../../components/input";
import { ArrowLeft } from "../../../components/icons";
import { routes } from "../../../routes/routes";
import { useForgotPassword } from "../../../hooks";
import { Loader } from "../../../components/loader";
import { Alert } from "../../../components/alert";

export const ForgotPasswordForm = () => {
  const { mutateAsync, isLoading, isSuccess } = useForgotPassword();

  if (isSuccess) {
    return (
      <Alert type="success">
        A link was sent to your email address to reset your password.
      </Alert>
    );
  }

  return (
    <Formik
      initialValues={{
        email: "",
      }}
      onSubmit={async (values) => {
        try {
          await mutateAsync({
            email: values.email,
          });
        } catch (err) {}
      }}
    >
      <>
        <p className="mt-3 text-sm mt-2">
          We will send an email containing the instructions to reset your
          password.
        </p>

        <Form className="w-full flex max-w-450  flex-col mt-3">
          <Input
            fieldAttributes={{
              name: "email",
              placeholder: "Enter your email address",
            }}
          />

          <button
            type="submit"
            className="py-3 px-5 text-black mt-3 bg-lightBlue rounded-md text-black text-sm text-center"
          >
            {isLoading && <Loader />}
            {!isLoading && <span>Reset Password</span>}
          </button>
        </Form>

        <Link to={routes.LOGIN}>
          <p className="mt-30 py-3 text-sm hover:text-[#8ce8e8] flex justify-center items-center hover:opacity-100">
            <ArrowLeft className="mr-2" />
            <span> Go back to login</span>
          </p>
        </Link>
      </>
    </Formik>
  );
};
