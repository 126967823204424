import React from "react";
import { Loader } from "../../../../../components/loader";
function BillPortal() {
  return (
    <div className="flex justify-center items-center text-white rounded-2xl border border-solid border-yellow-400 ml-8 bg-black bg-opacity-70 w-custom h-custom">
      <Loader />
    </div>
  );
}

export default BillPortal;
