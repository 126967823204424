import clsx from "clsx";
import React, { FunctionComponent } from "react";

export type ArrowLeftProps = {
  className?: string;
  fill?: string;
};

export const ArrowLeft: FunctionComponent<ArrowLeftProps> = ({
  className,
  fill,
}) => {
  return (
    <svg
      className={clsx("w-4 h-10", className)}
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill={fill || "none"}
      viewBox="0 0 14 10"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M13 5H1m0 0 4 4M1 5l4-4"
      />
    </svg>
  );
};
