const responseInterceptor = (axiosInstance) => {
  axiosInstance.interceptors.response.use(
    (res) => {
      return Promise.resolve(res);
    },
    (error) => {
      if (error?.status?.code === 401) {
        // Not implemented yet
      } else {
        return Promise.reject(error.response);
      }
    }
  );
};

export default responseInterceptor;
