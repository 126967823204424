import * as Yup from "yup";

import { messages } from "./messages";
import { IntlShape } from "react-intl";

export const ChangeDisplayNameValidationSchema = (intl: IntlShape) =>
  Yup.object({
    displayName: Yup.string().required(
      intl.formatMessage(messages.validationFieldRequired)
    ),
  });
