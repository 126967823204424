import { formatDistanceToNow } from "date-fns";
import { FunctionComponent } from "react";

export type AccountCreatedAtProps = {
  createdAt: Date | null;
};

export const AccountCreated: FunctionComponent<AccountCreatedAtProps> = ({
  createdAt,
}) => {
  if (!createdAt) {
    return null;
  }

  return (
    <div>
      <h4 className="font-bold text-base">Account Created</h4>
      <span className="text-sm opacity-60 mt-4">
        {formatDistanceToNow(new Date(createdAt), {
          addSuffix: true,
        })}
      </span>
    </div>
  );
};
