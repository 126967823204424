import { defineMessages } from "react-intl";

export const messages = defineMessages({
  fieldDisplayNameLabel: {
    id: "account-info.general-details.display-name.modal.form.field.display-name.label",
    description:
      "Account info - general detail - display name - modal - form - field - display name - label",
    defaultMessage: "Display name",
  },
  submitText: {
    id: "account-info.general-details.display-name.modal.form.submit",
    description:
      "Account info - general detail - display name - modal - form - submit",
    defaultMessage: "Save",
  },
  validationFieldRequired: {
    id: "account-info.general-details.display-name.modal.form.validation.field.required",
    description:
      "Account info - general detail - display name - modal - form - validation - required",
    defaultMessage: "Required",
  },
  unexpectedError: {
    id: "account-info.general-details.display-name.modal.form.error.unexpected",
    description:
      "Account info - general detail - display name - modal - form - error - unexpected",
    defaultMessage: "Unexpected error! Please try again",
  },
  nameTakenError: {
    id: "account-info.general-details.display-name.modal.form.error.name-taken",
    description:
      "Account info - general detail - display name - modal - form - error - name taken",
    defaultMessage: "The provided display name is already taken",
  },
  success: {
    id: "account-info.general-details.display-name.modal.form.success",
    description:
      "Account info - general detail - display name - modal - form - success",
    defaultMessage: "Your display name has been updated successfully",
  },
});
