import { FunctionComponent, PropsWithChildren } from "react";

export const DashboardContentHeader: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  return (
    <div className="border-b border- border-opacity-50 px-5 py-5">
      {children}
    </div>
  );
};
