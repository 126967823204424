import React from "react";
import { motion } from "framer-motion";

import mainPageHeroScanlines from "../assets/images/scanlines.png";
import mainPageHeroVideo from "../assets/video/mainPageHero.mp4";
import mainPageHeroLogoImg from "../assets/images/mrLogoFullWhite.png";
import mainPageHeroLogoWebp from "../assets/images/mrLogoFullWhite.webp";

import About from "../components/home/About";

function Main() {
  return (
    <motion.main
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="bg-zinc-900"
    >
      <div className="flex flex-col w-full h-screen overflow-hidden">
        <section className="relative md:-mt-32 lg:-mt-16 h-screen bg-zinc-900">
          <div className="absolute inset-0">
            <video
              autoPlay
              loop
              muted
              className="w-auto min-w-full min-h-full max-w-none"
            >
              <source src={mainPageHeroVideo} type="video/mp4" />
            </video>
          </div>
          <div className="absolute inset-0 z-10">
            <img
              src={mainPageHeroScanlines}
              alt="Page Hero Video Scanlines"
              className="min-h-full w-full object-cover opacity-50"
            />
          </div>
          <div className="absolute inset-0 z-20 flex items-center justify-center">
            <span className="sr-only">Mankind Reborn</span>
            <picture>
              <source srcSet={mainPageHeroLogoWebp} type="image/webp" />
              <img
                src={mainPageHeroLogoImg}
                alt="Mankind Reborn"
                className="aspect-auto w-64 md:w-80 lg:w-96 lg:pt-10"
              />
            </picture>
          </div>
        </section>
      </div>
      {/* TO DO change Recentnews component when blog url is ok*}
      {/*<RecentNews /> */}
      <About />
    </motion.main>
  );
}

export default Main;
