import React, { FunctionComponent, ReactNode } from "react";
import loginBackgroundImg from "../../assets/images/loginBackground.jpg";
import { motion } from "framer-motion";

export type PageWithFullScreenBackgroundImageProps = {
  imageSrc?: string;
  imageAlt?: string;
  children?: ReactNode;
};

export const PageWithFullScreenBackgroundImage: FunctionComponent<
  PageWithFullScreenBackgroundImageProps
> = ({ imageSrc, imageAlt, children }) => {
  return (
    <motion.main
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="bg-zinc-900"
    >
      <div className="flex flex-col w-full ">
        <section
          id="loginPage"
          className="relative grid place-items-center h-full min-h-screen  bg-zinc-900 bg-fixed bg-cover p-5 md:p-10 lg:p-15 xl:p-20"
        >
          <div className="absolute inset-0">
            <picture>
              <img
                src={imageSrc || loginBackgroundImg}
                alt={imageAlt || "Letter to our Community"}
                className="h-full w-full object-cover"
              />
            </picture>
          </div>
          {children}
        </section>
      </div>
    </motion.main>
  );
};
