import { FunctionComponent } from "react";
import { Modal } from "../../../../../../components/modal";
import { useIntl } from "react-intl";
import { messages } from "./messages";
import { ImportGamesparksForm } from "./forms";

export type ImportGamesparksModalProps = {
  onDismiss: () => void;
};

export const ImportGamesparksModal: FunctionComponent<
  ImportGamesparksModalProps
> = ({ onDismiss }) => {
  const intl = useIntl();

  return (
    <Modal
      onDismiss={onDismiss}
      closable
      title={intl.formatMessage(messages.modalTitle)}
    >
      <div className="text-white">
        <ImportGamesparksForm onSuccess={onDismiss} />
      </div>
    </Modal>
  );
};
