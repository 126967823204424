import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
} from "react";
import { CurrentUser } from "../../types";
import { useLocation } from "react-router-dom";
import { useGetCurrentUser } from "../../hooks";
import { useCookies } from "react-cookie";

export type CurrentUserContextType = {
  user: CurrentUser | null;
  isLoading?: boolean;
  logout?: () => void;
  refresh: () => void;
};

export const CurrentUserContext = createContext<CurrentUserContextType>({
  user: null,
  isLoading: true,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  refresh: () => {},
});

const cookieOptions = {
  path: "/",
  sameSite: true,
};

export const CurrentUserContextProvider = (props: PropsWithChildren) => {
  const { pathname } = useLocation();

  const [cookies, setCookie, removeCookie] = useCookies(["currentUser"]);

  const { mutateAsync: mutateGetCurrentUser, isLoading } = useGetCurrentUser();

  const getUserAndSetAuthCookie = () => {
    mutateGetCurrentUser()
      .then((user) => setCookie("currentUser", user, cookieOptions))
      .catch(() => removeCookie("currentUser", cookieOptions));
  };

  useEffect(() => {
    getUserAndSetAuthCookie();
  }, [pathname]);

  return (
    <CurrentUserContext.Provider
      value={{
        user: cookies.currentUser,
        isLoading: isLoading && !cookies.currentUser,
        logout: () => {
          removeCookie("currentUser", cookieOptions);
          setCookie("currentUser", null, cookieOptions);
          getUserAndSetAuthCookie();
        },
        refresh: getUserAndSetAuthCookie,
      }}
    >
      {props.children}
    </CurrentUserContext.Provider>
  );
};

export const useCurrentUser = () => useContext(CurrentUserContext);
