import { FunctionComponent, useMemo, useState } from "react";
import "../entitlements.css";
import EntitlementGridItem from "./EntitlementGridItem";
import { Entitlement } from "../../../../../../types";
import { paginate } from "../../../../../../utils/misc";
import { PaginationLinks } from "../../../../../../components/paginationLinks";

export type EntitlementsGridProps = {
  entitlements: Entitlement[];
};

const EntitlementsGrid: FunctionComponent<EntitlementsGridProps> = ({
  entitlements,
}) => {
  const [currentPage, setCurrentPage] = useState(1);

  const pagination = useMemo(
    () =>
      paginate<Entitlement>({
        items: entitlements,
        perPage: 10,
        page: currentPage,
      }),
    [currentPage, entitlements]
  );

  return (
    <div className="p-5 flex flex-col items-center justify-between h-full">
      <div className="mb-5">
        <PaginationLinks
          currentPage={currentPage}
          totalPages={pagination.totalPages}
          setCurrentPage={(page) => setCurrentPage(page)}
        />
      </div>
      <div className="entitlements-grid">
        {pagination.items.map((el, index) => (
          <EntitlementGridItem key={index} entitlement={el} />
        ))}
      </div>
    </div>
  );
};

export default EntitlementsGrid;
