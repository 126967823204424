import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { messages } from "./messages";
import { useCurrentUser } from "../../../../../../contexts/CurrentUser";
import { ButtonYellow } from "../../../../../../components/button";
import { ChangeDisplayNameModal } from "./ChangeDisplayNameModal";
import { ButtonText } from "../../../../../../components/button/ButtonText";

export const DisplayName = () => {
  const { user } = useCurrentUser();
  const [showChangeDisplayNameModal, setShowChangeDisplayNameModal] =
    useState(false);

  if (!user) {
    return null;
  }

  const displayName = user.displayName;

  return (
    <>
      <h4 className="font-bold text-base">
        <FormattedMessage {...messages.title} />
      </h4>
      <div className="text-sm mt-1 flex items-center">
        {displayName && <span className="opacity-60 mr-2">{displayName}</span>}
        {displayName && (
          <ButtonText onClick={() => setShowChangeDisplayNameModal(true)}>
            <FormattedMessage {...messages.changeDisplayName} />
          </ButtonText>
        )}

        {!displayName && (
          <ButtonYellow onClick={() => setShowChangeDisplayNameModal(true)}>
            <FormattedMessage {...messages.setDisplayName} />
          </ButtonYellow>
        )}
      </div>
      {showChangeDisplayNameModal && (
        <ChangeDisplayNameModal
          onDismiss={() => setShowChangeDisplayNameModal(false)}
        />
      )}
    </>
  );
};
