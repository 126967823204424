import clsx from "clsx";
import { FunctionComponent } from "react";

export type ButtonTextProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  className?: string;
};

export const ButtonText: FunctionComponent<ButtonTextProps> = ({
  children,
  className = "",
  ...props
}) => {
  return (
    <button
      className={clsx(
        "text-sm font-bold uppercase text-yellow-500",
        "transition duration-200 ease-in-out hover:",
        { className }
      )}
      {...props}
    >
      {children}
    </button>
  );
};
