import { FunctionComponent, PropsWithChildren } from "react";

export const DashboardContentContainer: FunctionComponent<
  PropsWithChildren
> = ({ children }) => {
  return (
    <div className="rounded-2xl border border-solid border-yellow-400 ml-8 bg-black bg-opacity-70 w-custom min-h-400 text-white">
      {children}
    </div>
  );
};
