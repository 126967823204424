import { useQuery } from "@tanstack/react-query";
import httpClient from "../../../../config/interceptors/http.interceptor";
import { toast } from "react-toastify";
import { useIntl } from "react-intl";
import { messages } from "../messages";

type ErrorMessage = "INVALID_TOKEN" | "EMAIL_NOT_FOUND" | "ALREADY_LINKED";

export const useConfirmGamesparksDonationLink = ({
  token,
  onSuccess,
}: {
  token: string;
  onSuccess?: () => void;
}) => {
  const intl = useIntl();

  return useQuery(
    ["PATCH /donation/gamesparks-donation/confirm-link"],
    () =>
      httpClient.patch("/donation/gamesparks-donation/confirm-link", { token }),
    {
      onSuccess: () => {
        toast(intl.formatMessage(messages.success), {
          type: "success",
        });
        if (onSuccess) {
          onSuccess();
        }
      },
      onError: () => {
        toast(intl.formatMessage(messages.invalidToken), {
          type: "error",
        });
      },
    }
  );
};
