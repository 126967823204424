import React from "react";

export const dashboardMenuItems = [
  {
    name: "General Details",
    path: "/account/general-details",
    icon: <i className="fa fa-cog mr-3"></i>,
  },
  {
    name: "Entitlements",
    path: "/account/entitlements",
    icon: <i className="fa fa-birthday-cake mr-3"></i>,
  },
  /*
  {
    name: "Billing Portal",
    path: "/account/billing-portal",
    icon: <i className="fa fa-credit-card mr-3"></i>,
  },
  */
  /*
  {
    name: "Donation History",
    path: "/account/donation-history",
    icon: <i className="fa fa-hand-holding-usd mr-3"></i>,
  },
  */
  {
    name: "Discord",
    path: "/account/discord-account",
    icon: <i className="fab fa-discord mr-3"></i>,
  },
  {
    name: "Linked Accounts",
    path: "/account/linked-accounts",
    icon: <i className="fa-solid fa-link"></i>,
  },
];
