import { useRef, useState } from "react";
import { Field, Formik } from "formik";
import { FormattedMessage, useIntl } from "react-intl";
import * as Yup from "yup";
import { UserService } from "../../../../services/user.service";
import { RegisterApiResponse } from "../../../../types/RegisterApiResponse ";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../../routes/routes";
import ReCAPTCHA from "react-google-recaptcha";
import { messages } from "./messages";

interface RegisterDataForm {
  username: string;
  email: string;
  password: string;
  confirmPassword: string;
  token: string;
  registrationCode: string;
}

function RegisterForm() {
  const intl = useIntl();
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const navigate = useNavigate();
  const [errorReCaptcha, setErrorReCaptcha] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const SignupSchema = Yup.object().shape({
    email: Yup.string()
      .email(
        intl.formatMessage({
          id: `register.invalid_email`,
          defaultMessage: "The provided email is invalid, try again!",
        })
      )
      .required(
        intl.formatMessage({
          id: `register.email_required`,
          defaultMessage: "Email is required",
        })
      ),
    username: Yup.string()
      .matches(
        /^[a-zA-Z0-9]+$/,
        intl.formatMessage({
          id: `register.invalid_username`,
          defaultMessage: "The provided username is invalid, try again!",
        })
      )
      .min(
        3,
        intl.formatMessage({
          id: `register.username_too_short`,
          defaultMessage: "Username must be at least 3 characters long",
        })
      )
      .max(
        20,
        intl.formatMessage({
          id: `register.username_too_long`,
          defaultMessage: "Username must be less than 20 characters long",
        })
      )
      .required(
        intl.formatMessage({
          id: `register.username_required`,
          defaultMessage: "Username is required",
        })
      ),
    password: Yup.string()
      .min(
        6,
        intl.formatMessage({
          id: `register.password_too_short`,
          defaultMessage: "Password must be at least 6 characters long",
        })
      )
      .max(
        100,
        intl.formatMessage({
          id: `register.password_too_long`,
          defaultMessage: "Password must be less than 100 characters long",
        })
      )
      .required(
        intl.formatMessage({
          id: `register.password_required`,
          defaultMessage: "Password is required",
        })
      ),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref("password")],
      intl.formatMessage({
        id: `register.passwords_don_t_match`,
        defaultMessage: "Password not match!",
      })
    ),
    isAgree: Yup.boolean().oneOf(
      [true],
      intl.formatMessage({
        id: `register.you_must_agree`,
        defaultMessage: "You must agree to our terms and conditions",
      })
    ),
    registrationCode: Yup.string().required(
      intl.formatMessage(messages.registrationCodeRequired)
    ),
  });

  return (
    <Formik
      initialValues={{
        username: "",
        email: "",
        password: "",
        confirmPassword: "",
        isAgree: false,
        registrationCode: "",
      }}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={SignupSchema}
      onSubmit={(values, actions) => {
        setIsLoading(true);
        if (!recaptchaRef.current) {
          setIsLoading(false);
          return;
        }
        const token = recaptchaRef.current.getValue();
        if (!token) {
          setIsLoading(false);
          setErrorReCaptcha("Recaptcha is required");
          return;
        } else {
          setErrorReCaptcha("");
        }

        const data: RegisterDataForm = {
          ...values,
          token,
        };
        new UserService()
          .register(data)
          .then(() => {
            actions.resetForm();
            setIsLoading(false);
            setIsSuccess(true);
            setTimeout(() => {
              navigate(routes.LOGIN);
            }, 2000);
          })
          .catch((error: RegisterApiResponse) => {
            setIsLoading(false);

            switch (error?.data?.message) {
              case "INVALID_REGISTRATION_CODE":
                actions.setFieldError(
                  "registrationCode",
                  intl.formatMessage(messages.registrationCodeInvalid)
                );
                break;
              default:
                break;
            }

            switch (error.data?.error) {
              case "UsernameNotAvailable":
                actions.setFieldError(
                  "username",
                  intl.formatMessage({
                    id: `register.username_already_exist`,
                    defaultMessage: "Username already exist",
                  })
                );
                break;
              case "EmailAddressNotAvailable":
                actions.setFieldError(
                  "email",
                  intl.formatMessage({
                    id: `register.email_already_exist`,
                    defaultMessage: "Email already exist",
                  })
                );
                break;
              case "Bad Request":
                if (Array.isArray(error.data?.message)) {
                  error.data?.message.forEach((message) => {
                    if (message.match(/email/gi)) {
                      actions.setFieldError(
                        "email",
                        intl.formatMessage({
                          id: `register.invalid_email`,
                          defaultMessage:
                            "The provided email is invalid, try again!",
                        })
                      );
                    }
                    if (message.match(/username/gi)) {
                      actions.setFieldError(
                        "username",
                        intl.formatMessage({
                          id: `register.invalid_username`,
                          defaultMessage:
                            "The provided username is invalid, try again!",
                        })
                      );
                    }
                  });
                }
                break;
              default:
                break;
            }
          });
      }}
    >
      {({ errors, touched, handleSubmit, handleChange, setFieldError }) => (
        <form onSubmit={handleSubmit}>
          {isSuccess && (
            <p className="text-center pb-3 text-green-500">
              <FormattedMessage
                id={`register.success`}
                defaultMessage="You have successfully registered,you can now login"
              />
            </p>
          )}
          <div className="flex flex-col mb-2">
            <label htmlFor="">
              <FormattedMessage id="username" defaultMessage="Username" />
            </label>

            <Field
              name="username"
              type="text"
              className="w-full bg-black text-xs p-2 rounded-lg outline-none"
              onChange={(e) => {
                setFieldError("username", "");
                handleChange(e);
              }}
            />

            {touched.username && errors.username && (
              <p className="error-message">{errors.username}</p>
            )}
          </div>
          <div className="flex flex-col mb-2">
            <label htmlFor="">
              <FormattedMessage
                id="email_address"
                defaultMessage="Email Address"
              />
            </label>
            <Field
              name="email"
              type="email"
              className="w-full bg-black text-xs p-2 rounded-lg outline-none"
              onChange={(e) => {
                setFieldError("email", "");
                handleChange(e);
              }}
            />
            {touched.email && errors.email && (
              <p className="error-message">{errors.email}</p>
            )}
          </div>
          <div className="flex flex-col mb-2">
            <label htmlFor="">
              <FormattedMessage id="password" defaultMessage="Password" />
            </label>
            <Field
              name="password"
              type="password"
              className="w-full bg-black text-xs p-2 rounded-lg outline-none"
              onChange={(e) => {
                setFieldError("password", "");
                handleChange(e);
              }}
            />
            {touched.password && errors.password && (
              <p className="error-message">{errors.password}</p>
            )}
          </div>
          <div className="flex flex-col mb-2">
            <label htmlFor="">
              <FormattedMessage
                id="confirm_password"
                defaultMessage="Confirm Password"
              />
            </label>
            <Field
              name="confirmPassword"
              type="password"
              className="w-full bg-black text-xs p-2 rounded-lg outline-none"
              onChange={(e) => {
                setFieldError("confirmPassword", "");
                handleChange(e);
              }}
            />
            {touched.confirmPassword && errors.confirmPassword && (
              <p className="error-message">{errors.confirmPassword}</p>
            )}
          </div>
          <div className="flex flex-col mb-2">
            <label htmlFor="">
              <FormattedMessage
                id="registration_code"
                defaultMessage="Registration code"
              />
            </label>
            <Field
              name="registrationCode"
              type="text"
              className="w-full bg-black text-xs p-2 rounded-lg outline-none"
              onChange={(e) => {
                setFieldError("registrationCode", "");
                handleChange(e);
              }}
            />
            {touched.registrationCode && errors.registrationCode && (
              <p className="error-message">{errors.registrationCode}</p>
            )}
          </div>
          {process.env.REACT_APP_RECAPTCHA_APP_SITE_KEY && (
            <div className="flex flex-col mb-2">
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={process.env.REACT_APP_RECAPTCHA_APP_SITE_KEY}
                hl="en" // change the language of ReCaptcha to English
                theme="dark" // change theme on dark , because default is light
                onChange={(e) => {
                  if (recaptchaRef.current?.getValue()) {
                    setErrorReCaptcha("");
                  }
                }}
              />
              {errorReCaptcha.length > 1 && (
                <p className="error-message">{errorReCaptcha}</p>
              )}
            </div>
          )}
          <div className="flex justify-between items-center gap-2">
            <label className="second_label" htmlFor="agree">
              <a
                href="https://www.mankindreborn.com/terms-of-service/"
                target="_blank"
                rel="noreferrer"
              >
                Terms of Service
              </a>
              ,
              <a
                href="https://www.mankindreborn.com/privacy-policy/"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
              ,<label>and</label>
              <a
                href="https://www.mankindreborn.com/gameplay-guidelines/"
                target="_blank"
                rel="noreferrer"
              >
                Gameplay Guidelines
              </a>
              .
            </label>
            <Field
              name="isAgree"
              type="checkbox"
              onChange={(e) => {
                setFieldError("isAgree", "");
                handleChange(e);
              }}
            />
          </div>
          {touched.isAgree && errors.isAgree && (
            <p className="error-message">{errors.isAgree}</p>
          )}
          <button
            type="submit"
            className="bg-[#8be6e6] w-full p-2 rounded-lg text-black mt-2"
          >
            {isLoading ? (
              <div className="flex-shrink-0">
                <FontAwesomeIcon
                  icon={faSpinner}
                  className="h-4 w-4 text-white self-center animate-spin"
                />
              </div>
            ) : (
              <FormattedMessage id="register" defaultMessage="Register" />
            )}
          </button>
        </form>
      )}
    </Formik>
  );
}

export default RegisterForm;
