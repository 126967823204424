import React, { useState } from "react";
import { ButtonYellow } from "../../../../../components/button";
import { ImportGamesparksModal } from "./ImportGamesparksModal/ImportGamesparksModal";
import { useLinkedGamesparksAccount } from "../../../../../hooks";

export const GeneralDetailHeader = () => {
  const [showImportGamesparksModal, setShowImportGamesparksModal] =
    useState(false);
  const linkedGamesparksAccount = useLinkedGamesparksAccount();

  return (
    <>
      <div className="w-full h-20 border-b border- border-opacity-50 flex justify-between items-center px-5 py-5">
        <div>
          <h4 className="font-bold text-base">General Account Details</h4>
          <span className="text-xs opacity-60 mt-4">
            Below is a general information about your account!
          </span>
        </div>
        {!linkedGamesparksAccount && (
          <ButtonYellow onClick={() => setShowImportGamesparksModal(true)}>
            Import GS Account
          </ButtonYellow>
        )}
      </div>
      {showImportGamesparksModal && (
        <ImportGamesparksModal
          onDismiss={() => setShowImportGamesparksModal(false)}
        />
      )}
    </>
  );
};
