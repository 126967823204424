import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import RegisterForm from "./register/components/RegisterForm";
import { Card } from "../../components/card/Card";
import { PageWithFullScreenBackgroundImage } from "../../components/PageWithFullScreenBackground";

function Register() {
  return (
    <PageWithFullScreenBackgroundImage>
      <Card>
        <RegisterForm />
        <p className="text-sm mt-2">
          <FormattedMessage
            id="have_account"
            defaultMessage="Already a member? "
          />
          <Link to="/account" className="hover:text-[#8ce8e8]">
            <FormattedMessage id="login" defaultMessage="Login" />
          </Link>
        </p>
      </Card>
    </PageWithFullScreenBackgroundImage>
  );
}

export default Register;
