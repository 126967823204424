import * as Yup from "yup";
import { messages } from "./messages";
import { IntlShape } from "react-intl";

export const SetUsernameValidationSchema = (intl: IntlShape) =>
  Yup.object({
    email: Yup.string()
      .email(intl.formatMessage(messages.validationFieldEmail))
      .required(intl.formatMessage(messages.validationFieldRequired)),
    username: Yup.string()
      .matches(
        /^[a-zA-Z0-9]+$/,
        intl.formatMessage(messages.validationFieldUsername)
      )
      .min(3, intl.formatMessage(messages.validationFieldUsername))
      .max(20, intl.formatMessage(messages.validationFieldUsername))
      .required(intl.formatMessage(messages.validationFieldRequired)),
    password: Yup.string()
      .min(6, intl.formatMessage(messages.validationFieldPassword))
      .max(100, intl.formatMessage(messages.validationFieldPassword))
      .required(intl.formatMessage(messages.validationFieldRequired)),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref("password")],
      intl.formatMessage(messages.validationConfirmPassword)
    ),
  });
