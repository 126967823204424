import { useMutation } from "@tanstack/react-query";
import httpClient from "../../../../../../../config/interceptors/http.interceptor";
import { ImportGamesparksAccountError } from "./types";
import { useIntl } from "react-intl";
import { toast } from "react-toastify";
import { messages } from "./messages";

export const useImportGamesparks = () => {
  const intl = useIntl();

  return useMutation(
    ["PATCH donation/gamesparks-donation/link"],
    (email: string) =>
      httpClient.patch("donation/gamesparks-donation/link", {
        email,
      }),
    {
      onError({
        data: { message },
      }: {
        data: {
          message?: ImportGamesparksAccountError;
        };
      }) {
        if (!message) {
          toast(intl.formatMessage(messages.unexpectedError), {
            type: "error",
          });
        }

        if (message === "EMAIL_NOT_FOUND") {
          toast(intl.formatMessage(messages.emailNotFoundError), {
            type: "error",
          });
        } else if (message === "ALREADY_LINKED") {
          toast(intl.formatMessage(messages.alreadyLinkedError), {
            type: "error",
          });
        }
      },
    }
  );
};
