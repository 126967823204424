import EntitlementsGrid from "./components/EntitlementsGrid";
import { useEntitlements } from "./hooks/useEntitlements";

function Entitlements() {
  const entitlements = useEntitlements();

  return (
    <div className="flex justify-center text-white">
      <div className="text-white w-full h-full">
        <div className="p-5  border-b border-solid">
          <h4 className="font-bold text-base">Entitlements</h4>
          <span className="text-xs opacity-60 mt-4">
            Discover your entitlements !
          </span>
        </div>
        {entitlements.length > 0 ? (
          <EntitlementsGrid entitlements={entitlements} />
        ) : (
          <div className="px-4 text-center py-8">
            <i className="fa fa-gift text-3xl"></i>
            <p>You do not have entitlements !</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default Entitlements;
