import { useMutation } from "@tanstack/react-query";
import httpClient from "../../config/interceptors/http.interceptor";
import { OperationStatusResponse } from "../../types";

export const useLogout = () => {
  const logout = async () => {
    return httpClient.get<OperationStatusResponse>("/user/logout");
  };

  return useMutation(["GET /user/logout"], logout);
};
