import { defineMessages } from "react-intl";

export const messages = defineMessages({
  subTitle: {
    id: "account-info.general-details.import-gamesparks.modal.subTitle",
    description:
      "Account info - general detail - import gamesparks - modal - subTitle",
    defaultMessage:
      "Once you verified your email, your gamesparks donations, tiers and rewards will be imported to your account.",
  },
  emailLabel: {
    id: "account-info.general-details.import-gamesparks.modal.form.email.label",
    description:
      "Account info - general detail - import gamesparks - modal - form - email - label",
    defaultMessage: "Email",
  },
  emailPlaceholder: {
    id: "account-info.general-details.import-gamesparks.modal.form.email.placeholder",
    description:
      "Account info - general detail - import gamesparks - modal - form - email - placeholder",
    defaultMessage: "Email you used with Gamesparks",
  },
  validationFieldRequired: {
    id: "account-info.general-details.import-gamesparks.modal.form.validation.field.required",
    description:
      "Account info - general detail - import gamesparks - modal - form - validation - required",
    defaultMessage: "Required",
  },
  validationFieldEmail: {
    id: "account-info.general-details.import-gamesparks.modal.form.validation.field.email",
    description:
      "Account info - general detail - import gamesparks - modal - form - validation - email",
    defaultMessage: "Invalid email",
  },

  submit: {
    id: "account-info.general-details.import-gamesparks.modal.form.submit",
    description:
      "Account info - general detail - import gamesparks - modal - form - submit",
    defaultMessage: "Import",
  },
  unexpectedError: {
    id: "account-info.general-details.import-gamesparks.modal.form.error.unexpected",
    description:
      "Account info - general detail - import gamesparks - modal - form - unexpected error",
    defaultMessage: "Unexpected error! Try again later",
  },
  emailNotFoundError: {
    id: "account-info.general-details.import-gamesparks.modal.form.error.email-not-found",
    description:
      "Account info - general detail - import gamesparks - modal - form - email not found error",
    defaultMessage:
      "We cannot find any gamesparks account associated with your email",
  },
  alreadyLinkedError: {
    id: "account-info.general-details.import-gamesparks.modal.form.error.already-linked",
    description:
      "Account info - general detail - import gamesparks - modal - form - already linked error",
    defaultMessage:
      "This gamesparks account is already linked to an existing account",
  },
  success: {
    id: "account-info.general-details.import-gamesparks.modal.form.success",
    description:
      "Account info - general detail - import gamesparks - modal - form - success",
    defaultMessage:
      "An email has been sent to import your gamesparks account, please check your inbox",
  },
});
