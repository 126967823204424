import * as Yup from "yup";
import { IntlShape } from "react-intl";

import { messages } from "./messages";

export const ImportGamesparksValidationSchema = (intl: IntlShape) =>
  Yup.object({
    email: Yup.string()
      .required(intl.formatMessage(messages.validationFieldRequired))
      .email(intl.formatMessage(messages.validationFieldEmail)),
  });
