import { FunctionComponent, PropsWithChildren } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSteam } from "@fortawesome/free-brands-svg-icons";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../../routes/routes";
import { usePlayFabLoginWithSteam, useSteam } from "../../../../hooks/steam";
import { Loader } from "../../../../components/loader";

export type LoginSteamProps = PropsWithChildren & {
  setErrorMessage: (message: string) => void;
};

export const LoginSteam: FunctionComponent<LoginSteamProps> = ({
  children,
  setErrorMessage,
}) => {
  const navigate = useNavigate();

  const { mutateAsync, isLoading } = usePlayFabLoginWithSteam();

  const { login } = useSteam({
    onSuccess: async (steamId) => {
      try {
        await mutateAsync(steamId);
        navigate(0);
        navigate(routes.ACCOUNT, { replace: true });
      } catch (err) {
        if (err?.data?.message === "AccountNotFound") {
          setErrorMessage(
            "We cannot find any account associated with your steam account."
          );
        } else {
          setErrorMessage("Something went wrong, please try again!");
        }
      }
    },
  });

  return (
    <div className="flex justify-center items-center">
      <button
        onClick={login}
        type="button"
        className="inline-flex justify-center items-center  rounded-md border border-transparent bg-zinc-700 px-6 py-2 w-full  text-base font-medium text-white shadow-sm mt-2 p-2  mb-2 hover:text-[#8ce8e8] focus:outline-none focus:ring-1 focus:ring-[#8ce8e8] focus:ring-offset-1"
      >
        <FontAwesomeIcon
          icon={faSteam}
          className="-ml-1 mr-3 h-5 w-5"
          aria-hidden="true"
        />
        {isLoading && <Loader />}
        {!isLoading && children}
      </button>
    </div>
  );
};
