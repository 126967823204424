import steamLogo from "../../../../../../../assets/images/Steam.png";
import { Link } from "../../../../../../../components/link";
import { useCurrentUser } from "../../../../../../../contexts/CurrentUser";
import { LinkSteam } from "./LinkSteam";

export const SteamLinkedAccount = () => {
  const { user } = useCurrentUser();

  return (
    <div className="flex">
      <img src={steamLogo} alt="steam" className="w-10 h-10" />
      <div className="ml-2">
        <h5>Steam</h5>
        {!user?.steam && (
          <div>
            <p className="text-xs opacity-60">No steam account linked</p>
            <LinkSteam>Link</LinkSteam>
          </div>
        )}
        {user?.steam && (
          <p className="text-xs opacity-60 font-semibold">
            <Link
              href={`https://steamcommunity.com/profiles/${user.steam.steamId}`}
              target="_blank"
              rel="noreferrer"
            >
              {user.steam.steamName}
            </Link>
          </p>
        )}
      </div>
    </div>
  );
};
