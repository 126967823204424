import { defineMessages } from "react-intl";

export const messages = defineMessages({
  modalTitle: {
    id: "account-info.general-details.import-gamesparks.modal.title",
    description:
      "Account info - general detail - import gamesparks - modal - title",
    defaultMessage: "Import Gamesparks account",
  },
});
