import React from "react";
import { Card } from "../../../components/card/Card";
import { ForgotPasswordForm } from "./ForgotPasswordForm";
import { PageWithFullScreenBackgroundImage } from "../../../components/PageWithFullScreenBackground";

export default function ForgotPassword() {
  return (
    <PageWithFullScreenBackgroundImage>
      <Card>
        <h1 className="text-2xl font-semibold">Forgot password ?</h1>

        <ForgotPasswordForm />
      </Card>
    </PageWithFullScreenBackgroundImage>
  );
}
