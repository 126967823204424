import clsx from "clsx";
import { FunctionComponent } from "react";

export type ButtonYellowProps =
  React.ButtonHTMLAttributes<HTMLButtonElement> & {
    className?: string;
  };

export const ButtonYellow: FunctionComponent<ButtonYellowProps> = ({
  children,
  className = "",
  ...props
}) => {
  return (
    <button
      className={clsx(
        "bg-yellow-400 text-sm text-white py-2 px-5 font-bold rounded",
        "transition duration-200 ease-in-out hover:bg-yellow-400",
        { className }
      )}
      {...props}
    >
      {children}
    </button>
  );
};
