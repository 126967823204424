import React, { useState } from "react";
import { motion } from "framer-motion";
import axios from "axios";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationCircle,
  faFloppyDisk,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";

import loginBackgroundWebp from "../../assets/images/loginBackground.webp";
import loginBackgroundImg from "../../assets/images/loginBackground.jpg";

function ProvideEmail() {
  const [emailAddress, setEmailAddress] = useState("");
  const [isLoading, setIsLoading] = useState<boolean>();
  const [error, setError] = useState();

  function handleSubmit(event) {
    setIsLoading(true);
    event.preventDefault();

    const data = {
      emailAddress,
    };

    axios
      .put(
        `${process.env.REACT_APP_VITE_ACCOUNT_API}/user/addOrUpdateEmailLocal`,
        data,
        { withCredentials: true }
      )
      .then((res) => {
        if (res.data.data === true) {
          setIsLoading(false);
          localStorage.removeItem("noEmail");
          window.location.reload();
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setError(err.response.data.data);
      });
  }

  return (
    <motion.main
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="bg-zinc-900"
    >
      <div className="flex flex-col w-full min-h-screen">
        <section
          id="loginPage"
          className="relative grid place-items-center h-full min-h-screen bg-zinc-900 bg-fixed bg-cover p-5 md:p-10 lg:p-15 xl:p-20"
        >
          <div className="absolute inset-0">
            <picture>
              <source srcSet={loginBackgroundWebp} type="image/webp" />
              <img
                src={loginBackgroundImg}
                alt="Letter to our Community"
                className="h-full w-full object-cover"
              />
            </picture>
          </div>
          <div className="bg-zinc-800 max-w-xl drop-shadow-xl rounded-2xl px-10 py-10 text-zinc-200 border-b border-t border-[#8ce8e8]">
            <div className="border-l-4 border-yellow-500 bg-zinc-700 p-3 mb-5">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <FontAwesomeIcon
                    icon={faExclamationCircle}
                    className="h-7 w-7 text-yellow-500 self-center"
                  />
                </div>
                <div className="ml-3 self-center">
                  <p className="text-sm text-zinc-100">
                    Please provide us with a valid/verifiable email address to
                    associate with your account. We use this to do stuff and
                    things like sell your info! This is honestly a scam.
                  </p>
                </div>
              </div>
            </div>
            <form onSubmit={handleSubmit}>
              <label htmlFor="emailAddress" className="sr-only">
                Email Address
              </label>
              <input
                type="email"
                name="emailAddress"
                id="emailAddress"
                value={emailAddress}
                onChange={(e) => setEmailAddress(e.target.value)}
                className="block w-full rounded-md bg-zinc-900 border-zinc-500 shadow-sm focus:border-[#8ce8e8] focus:ring-[#8ce8e8] sm:text-sm"
                placeholder="rampy@worlddomination.gov"
              />
              {error && (
                <p
                  className="text-sm text-red-600 font-exo-2 font-bold animate-pulse mt-3"
                  id="email-error"
                >
                  Unable to use the provided email address!
                  <br />
                  <span className="font-normal">
                    Please try a different one ({error}).
                  </span>
                </p>
              )}
              <button
                disabled={!!isLoading}
                type="submit"
                className="mt-3 inline-flex float-right items-center rounded-md border border-transparent bg-zinc-700 px-6 py-2 text-base font-medium text-white shadow-sm hover:text-[#8ce8e8] focus:outline-none focus:ring-1 focus:ring-[#8ce8e8] focus:ring-offset-1 disabled:text-zinc-400"
              >
                {isLoading ? (
                  <FontAwesomeIcon
                    icon={faSpinner}
                    className="-ml-1 mr-2 h-5 w-5 self-center animate-spin animate-pulse text-[#8ce8e8]"
                    aria-hidden="true"
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faFloppyDisk}
                    className="-ml-1 mr-2 h-5 w-5 self-center"
                    aria-hidden="true"
                  />
                )}
                Submit
              </button>
            </form>
          </div>
        </section>
      </div>
    </motion.main>
  );
}

export default ProvideEmail;
