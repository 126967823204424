import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  const intlPrefix = "footer.";
  return (
    <footer className="bg-zinc-900 border-t border-[#8ce8e8]">
      <div className="max-w-7xl mx-auto py-5 px-4 overflow-hidden sm:px-6 lg:px-8">
        <nav
          className="-mx-5 -my-2 flex flex-wrap justify-center"
          aria-label="Footer"
        >
          <Link
            to="/policy/privacy-policy"
            className="px-5 py-2 text-sm font-medium font-roboto-condensed uppercase text-zinc-300 hover:text-[#8ce8e8]"
          >
            Privacy Policy
          </Link>
          <Link
            to="/policy/terms-and-conditions"
            className="px-5 py-2 text-sm font-medium font-roboto-condensed uppercase text-zinc-300 hover:text-[#8ce8e8]"
          >
            Terms &amp; Conditions
          </Link>
        </nav>
        <p className="mt-3 text-center text-xs uppercase text-zinc-400">
          &copy; {new Date().getUTCFullYear()}{" "}
          <a
            href="https://www.farfrontierstudios.com"
            className="hover:text-[#8ce8e8] cursor-alias"
          >
            Far Frontier Studios, LLC
          </a>
        </p>
      </div>
    </footer>
  );
}

export default Footer;
