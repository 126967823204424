import { useState } from "react";
import ProvideEmail from "./ProvideEmail";
import Error503 from "../Error503";

import accountBackground from "../../assets/images/newAccoundBackground.png";

import Menu from "./dashboard/components/menu/Menu";
import { Outlet } from "react-router-dom";
import { DashboardContentContainer } from "./dashboard/DashboardContentContainer";

function AccountMain() {
  const [accountStatus] = useState(true);

  if (accountStatus === false) {
    return <Error503 system="Account Dashboard" />;
  }
  if (localStorage.getItem("noEmail") === "true") {
    return <ProvideEmail />;
  }

  // styling account config
  const styles = {
    backgroundImage: `url(${accountBackground})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  };

  return (
    <div
      className="flex flex-row items-start justify-center w-full h-screen p-6 "
      style={styles}
    >
      <Menu />
      <DashboardContentContainer>
        <Outlet />
      </DashboardContentContainer>
    </div>
  );
}

export default AccountMain;
