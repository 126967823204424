import { Formik, Form } from "formik";
import { Input } from "../../../../../../../components/input";
import { FormattedMessage, useIntl } from "react-intl";
import { messages } from "./messages";
import { Button } from "../../../../../../../components/button";
import { SetUsernameValidationSchema } from "./SetUsernameValidationSchema";
import { useSetUsernameAndPassword } from "./useSetUsernameAndPassword";
import { useCurrentUser } from "../../../../../../../contexts/CurrentUser";
import { FunctionComponent } from "react";
import { toast } from "react-toastify";

export type SetUsernameFormProps = {
  onSuccess: () => void;
};

export const SetUsernameForm: FunctionComponent<SetUsernameFormProps> = ({
  onSuccess,
}) => {
  const intl = useIntl();

  const { refresh } = useCurrentUser();

  const { mutateAsync, isLoading } = useSetUsernameAndPassword();

  const onSubmit = async (values: {
    username: string;
    email: string;
    password: string;
  }) => {
    await mutateAsync(values);
    toast(intl.formatMessage(messages.success), { type: "success" });
    refresh();
    onSuccess();
  };

  return (
    <Formik
      initialValues={{
        username: "",
        email: "",
        password: "",
        confirmPassword: "",
      }}
      validationSchema={SetUsernameValidationSchema(intl)}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={onSubmit}
    >
      {({ errors, handleSubmit, isSubmitting }) => (
        <Form onSubmit={handleSubmit}>
          <div className="mt-2 mb-2">
            <Input
              label={intl.formatMessage(messages.usernameLabel)}
              fieldAttributes={{
                name: "username",
                type: "text",
              }}
              error={errors.username}
            />
          </div>
          <div className="mt-2 mb-2">
            <Input
              label={intl.formatMessage(messages.emailLabel)}
              fieldAttributes={{
                name: "email",
                type: "email",
              }}
              error={errors.email}
            />
          </div>
          <div className="mt-2 mb-2">
            <Input
              label={intl.formatMessage(messages.passwordLabel)}
              fieldAttributes={{
                name: "password",
                type: "password",
              }}
              error={errors.password}
            />
          </div>
          <div className="mt-2 mb-2">
            <Input
              label={intl.formatMessage(messages.confirmPasswordLabel)}
              fieldAttributes={{
                name: "confirmPassword",
                type: "password",
              }}
              error={errors.confirmPassword}
            />
          </div>
          <Button
            type="submit"
            className="mt-4 mb-2"
            isLoading={isLoading || isSubmitting}
          >
            <FormattedMessage {...messages.submitText} />
          </Button>
        </Form>
      )}
    </Formik>
  );
};
