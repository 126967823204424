import React from "react";
import { Loader } from "../loader";
const FullScreenLoader = () => {
  return (
    <div className="flex flex-row justify-center items-center w-full h-screen p-6 ">
      <Loader className="text-white" />
    </div>
  );
};

export { FullScreenLoader };
