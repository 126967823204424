import React, { memo } from "react";
import { DiscordConnexion } from "./DiscordConnexion";
import { DiscordConnectedUser } from "./DiscordConnectedUser";
import DiscordLoader from "./DiscordLoader";
import { useGetDiscordInfo } from "../../../../../hooks/discord/useDiscord";

const DiscordAccountLinkComponent = () => {
  const { data, isLoading, isError } = useGetDiscordInfo();

  if (isLoading) {
    return <DiscordLoader />;
  }

  if (isError) {
    return <DiscordConnexion />;
  }

  return <DiscordConnectedUser discordUser={data} />;
};

export const DiscordAccountLink = memo(DiscordAccountLinkComponent);
