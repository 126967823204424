import React from "react";
import { useCurrentUser } from "../../../../../contexts/CurrentUser";
import { AccountTypeSection } from "./AccountTypeSection";
import { AccountCreated } from "./AccountCreated";
import { UserEmail } from "./UserEmail";
import { Username } from "./Username";
import { GeneralDetailHeader } from "./GeneralDetailHeader";
import clsx from "clsx";
import { DisplayName } from "./DisplayName/DisplayName";

function GeneralDetails() {
  const { user } = useCurrentUser();

  if (!user) {
    return null;
  }

  return (
    <div className={clsx("flex flex-col justify-between items-center")}>
      <GeneralDetailHeader />

      {/* TO DO use tailwind grid system instead */}

      <div className="details-body flex w-full h-full justify-between p-5">
        <div className="flex flex-col">
          <Username />

          <div className="mt-5">
            {user.email && <UserEmail email={user.email} />}
          </div>

          <div className="mt-5">
            <DisplayName />
          </div>
        </div>
        {/* right user info */}
        <div className="flex flex-col">
          <AccountTypeSection />
          <div className="mt-5">
            <AccountCreated createdAt={user.createdAt} />
          </div>
          {/*
          <div className="mt-3">
            <Newsletter />
          </div>
          <div className="info-ip">
            <h4 className="font-bold text-base">Last IP Address</h4>
            <span className="text-sm opacity-60 mt-4">192.168.1.1</span>
          </div>
          */}
        </div>
      </div>
    </div>
  );
}

export default GeneralDetails;
