import { defineMessages } from "react-intl";

export const messages = defineMessages({
  title: {
    id: "account-info.general-details.display-name.title",
    description: "Account info - general detail - display name - title",
    defaultMessage: "Display name",
  },
  setDisplayName: {
    id: "account-info.general-details.display-name.set-button.text",
    description: "Account info - general detail - display name - set button",
    defaultMessage: "Set display name",
  },
  changeDisplayName: {
    id: "account-info.general-details.display-name.change-button.text",
    description: "Account info - general detail - display name - change button",
    defaultMessage: "Change display name",
  },
});
