import { FunctionComponent } from "react";
import { Tooltip } from "react-tooltip";
import "../entitlements.css";
import { EntitlementImage } from "./EntitlementImage";
import { Entitlement } from "../../../../../../types";

export type EntitlementsGridProps = {
  entitlement: Entitlement;
};

const EntitlementGridItem: FunctionComponent<EntitlementsGridProps> = ({
  entitlement,
}) => {
  const tooltipId = `entitlement-${entitlement.id}`;

  return (
    <>
      <div
        className="entitlements-box w-full bg-black bg-opacity-70 border border-solid p-1 cursor-pointer pb-5"
        data-tooltip-id={tooltipId}
      >
        <EntitlementImage
          entitlement={entitlement}
          className="w-full my-1 px-1 py-1"
        />
      </div>
      <Tooltip id={tooltipId} place="bottom">
        <div>
          <h4 className="mb-2">{entitlement.name}</h4>
          <p className="mb-1">{entitlement.description}</p>
          <p className="text-xs text-zinc-300">{entitlement.dateTime}</p>
          <p
            className="text-xs text-zinc-300"
            style={{
              opacity: "0.5",
            }}
          >
            X{entitlement.count}
          </p>
        </div>
      </Tooltip>
    </>
  );
};

export default EntitlementGridItem;
