import { defineMessages } from "react-intl";

export const messages = defineMessages({
  usernameLabel: {
    id: "set-username-and-password.form.username.label",
    description: "Set username - Username label",
    defaultMessage: "Username",
  },
  emailLabel: {
    id: "set-username-and-password.form.email.label",
    description: "Set username - Email label",
    defaultMessage: "Email address",
  },
  passwordLabel: {
    id: "set-username-and-password.form.password.label",
    description: "Set username - Password label",
    defaultMessage: "Password",
  },
  confirmPasswordLabel: {
    id: "set-username-and-password.form.confirm-password.label",
    description: "Set username - Confirm password label",
    defaultMessage: "Confirm password",
  },
  submitText: {
    id: "set-username-and-password.form.submit",
    description: "Set username - submit text",
    defaultMessage: "Save",
  },

  validationFieldRequired: {
    id: "set-username-and-password.form.validation.field.required",
    description: "Set username - validation field required",
    defaultMessage: "Required",
  },
  validationFieldEmail: {
    id: "set-username-and-password.form.validation.field.email",
    description: "Set username - validation field email",
    defaultMessage: "The provided email is invalid",
  },
  validationFieldEmailNotAvailable: {
    id: "set-username-and-password.form.validation.field.email.not-available",
    description: "Set username - validation field email - not available",
    defaultMessage:
      "The provided email is already associated with an existing account",
  },
  validationFieldUsername: {
    id: "set-username-and-password.form.validation.field.username",
    description: "Set username - validation field username",
    defaultMessage:
      "Username cannot contains special character, must be at least 3 characters long and 20 characters at maximum",
  },
  validationFieldUsernameNotAvailable: {
    id: "set-username-and-password.form.validation.field.username.not-available",
    description: "Set username - validation field username - not available",
    defaultMessage:
      "The provided username is already associated with an existing account",
  },
  validationFieldPassword: {
    id: "set-username-and-password.form.validation.field.password",
    description: "Set username - validation field password",
    defaultMessage:
      "Password must be at least 6 characters long and 100 characters at maximum",
  },
  validationConfirmPassword: {
    id: "set-username-and-password.form.validation.confirm-password",
    description: "Set username - validation confirm password",
    defaultMessage: "Password don't match!",
  },
  validationAccountAlreadyLinked: {
    id: "set-username-and-password.form.validation.account-already-linked",
    description: "Set username - validation account already linked",
    defaultMessage: "Your account is already linked to an username/password",
  },
  unexpectedError: {
    id: "set-username-and-password.form.validation.unexpected",
    description: "Set username - unexpected error",
    defaultMessage: "Unexpected error! Please try again",
  },
  success: {
    id: "set-username-and-password.form.success",
    description: "Set username - success message",
    defaultMessage:
      "Your username and password have been successfully updated, you can now use them to log in",
  },
});
