import { Navigate, Outlet } from "react-router-dom";
import { useCurrentUser } from "../contexts/CurrentUser";

export const AuthGuard = () => {
  const { user, isLoading } = useCurrentUser();

  if (isLoading) {
    return null;
  }

  if (!user) {
    return <Navigate to="/login" replace />;
  }

  return <Outlet />;
};
