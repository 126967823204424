import { FunctionComponent } from "react";
import { Modal } from "../../../../../../components/modal";
import { SetUsernameForm } from "./forms";

export type SetUsernameModalProps = {
  onDismiss: () => void;
};

export const SetUsernameModal: FunctionComponent<SetUsernameModalProps> = ({
  onDismiss,
}) => {
  return (
    <Modal onDismiss={onDismiss} closable title="Add username and password">
      <div className="text-white">
        <SetUsernameForm onSuccess={onDismiss} />
      </div>
    </Modal>
  );
};
