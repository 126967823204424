import { useQuery } from "@tanstack/react-query";
import httpClient from "../../../config/interceptors/http.interceptor";
import { toast } from "react-toastify";

export const useVerifyEmail = ({
  userId,
  token,
  onSuccess,
}: {
  onSuccess?: () => void;
  userId: string;
  token: string;
}) => {
  return useQuery(
    ["PATCH /user/verify-account"],
    () =>
      httpClient.patch("/user/verify-account", {
        token,
        userId,
      }),
    {
      onSuccess: () => {
        if (onSuccess) {
          onSuccess();
        }
      },
      onError: ({
        data: { message },
      }: {
        data: {
          message?: "INVALID_TOKEN" | "";
        };
      }) => {
        if (message === "INVALID_TOKEN") {
          toast("Oops, the link you followed is incorrect or expired!", {
            type: "error",
          });
        } else {
          toast("Unexpected error! Please check your email and try again", {
            type: "error",
          });
        }
      },
    }
  );
};
