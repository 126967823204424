// eslint-disable-next-line import/prefer-default-export
export class LocalStorageUtil {
  private static readonly prefix = "playfab_";

  public static setItem(key: string, value?: string): void {
    if (value != null) {
      localStorage.setItem(this.prefix + key, value);
    }
  }

  public static getItem(key: string): string | null {
    return localStorage.getItem(this.prefix + key);
  }

  public static removeItem(key: string): void {
    localStorage.removeItem(this.prefix + key);
  }

  public static clear(): void {
    localStorage.clear();
  }
}
