import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useCurrentUser } from "../contexts/CurrentUser";

// Guard for pages that should not be accessed when logged in
export function GuestGuard() {
  const { user, isLoading } = useCurrentUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (user && !isLoading) {
      navigate("/account", { replace: true });
    }
  }, [isLoading, user, navigate]);

  if (isLoading) {
    return null;
  }

  return <Outlet />;
}
