import { FunctionComponent } from "react";

export type UserEmailProps = {
  email: string;
};

export const UserEmail: FunctionComponent<UserEmailProps> = ({ email }) => {
  return (
    <div>
      <h4 className="font-bold text-base">Email Address</h4>
      <span className="text-sm  mt-4">
        <span className="opacity-60">{email}</span>
        {/* <button className="bg-yellow-400 text-sm text-white py-2 px-5 font-bold rounded transition duration-200 ease-in-out hover:bg-yellow-400 ml-2 opacity-100">
          Change
        </button> */}
      </span>
    </div>
  );
};
