import React from "react";

function PageHeaderImg({ src, fallback, type = "image/webp", ...delegated }) {
  return (
    <picture>
      <source srcSet={src} type={type} />
      {/* eslint-disable-next-line */}
      <img src={fallback} {...delegated} />
    </picture>
  );
}

export default PageHeaderImg;
