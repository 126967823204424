import React from "react";
import aboutTheGameJPG from "../../assets/images/aboutTheGame.jpg";
import aboutTheGameWEBP from "../../assets/images/aboutTheGame.webp";

function About() {
  return (
    <div className="relative bg-zinc-900 border-t border-[#8ce8e8]">
      <div className="relative h-50 overflow-hidden bg-gray-900 md:absolute md:left-0 md:h-full md:w-1/3 lg:w-1/2">
        <picture>
          <source srcSet={aboutTheGameWEBP} type="image/webp" />
          <img
            src={aboutTheGameJPG}
            alt="About the Game"
            className="h-full w-full object-cover"
          />
        </picture>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 926 676"
          aria-hidden="true"
          className="absolute left-24 -bottom-24 w-[57.875rem] transform-gpu blur-[118px]"
        >
          <path
            fill="url(#60c3c621-93e0-4a09-a0e6-4c228a0116d8)"
            fillOpacity=".4"
            d="m254.325 516.708-90.89 158.331L0 436.427l254.325 80.281 163.691-285.15c1.048 131.759 36.144 345.144 168.149 144.613C751.171 125.508 707.17-93.823 826.603 41.15c95.546 107.978 104.766 294.048 97.432 373.585L685.481 297.694l16.974 360.474-448.13-141.46Z"
          />
          <defs>
            <linearGradient
              id="60c3c621-93e0-4a09-a0e6-4c228a0116d8"
              x1="926.392"
              x2="-109.635"
              y1=".176"
              y2="321.024"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#776FFF" />
              <stop offset={1} stopColor="#FF4694" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div className="relative mx-auto max-w-7xl py-8 md:py-16 lg:px-8">
        <div className="pr-6 pl-6 md:ml-auto md:w-2/3 md:pl-16 lg:w-1/2 lg:pl-24 lg:pr-0 xl:pl-32 font-exo-2">
          <h2 className="text-2xl font-semibold leading-7 text-[#8ce8e8]">
            About
          </h2>
          <p className="mt-2 text-4xl font-bold font-equinox tracking-tight text-white">
            The Game
          </p>
          <p className="mt-6 text-base leading-7 text-zinc-200">
            Mankind Reborn is a cyberpunk first and third-person MMO set in the
            near future. Eight player-run factions are engaged in endless
            galactic strife of commerce, intrigue, and warfare, each vying to
            secure their legacy amongst the stars.
            <br />
            <br />
            Combat is entirely skill-based, with full-loot on death rewarding
            those who master the art of war; however, every aspect of Mankind
            Reborn is player-driven, from the factions themselves to the
            economy, government, and more.
            <br />
            <br />
            Will you forge your destiny as an interstellar entrepreneur, a
            mercenary whose loyalty lies solely to the highest bidder, or a
            faction leader at the helm of the next chapter in mankind's errant
            journey?
            <br />
            <br />
            Economic domination, brutal conflicts, and constant politicking
            shift the lines of battle daily, factional feuds can last
            generations, and megacorporations fund high-tech low life in this
            dystopian sandbox experience. So where will you end up?
          </p>
        </div>
      </div>
    </div>
  );
}

export default About;
