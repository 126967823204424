import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import httpClient from "../../config/interceptors/http.interceptor";
import {
  registerDiscordErrorCode,
  unregisterDiscordErrorCode,
} from "./types/registerDiscordError";
import { DiscordUser } from "../../types";

export const useGetDiscordInfo = () => {
  return useQuery({
    queryKey: ["GET /discord"],
    queryFn: async () => {
      const { data } = await httpClient.get<DiscordUser>("/discord", {
        withCredentials: true,
      });

      return data;
    },
    retry: false,
    cacheTime: Infinity,
    staleTime: Infinity,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
};

export const useRefetchGetDiscordInfo = () => {
  const queryClient = useQueryClient();

  return {
    refetch: () => queryClient.refetchQueries(["GET /discord"]),
  };
};

export const useRegisterDiscord = () => {
  const postRegisterDiscord = async (data: { discordUserId: string }) => {
    await httpClient.post("/discord/register-discord", data);
  };
  return useMutation(["POST /discord/register-discord"], postRegisterDiscord, {
    onError({
      data: { message },
    }: {
      data: {
        message?: registerDiscordErrorCode;
      };
    }) {
      if (!message) {
        toast("Unexpected error! Please re-give authorization and try again", {
          type: "error",
        });
      } else {
        toast("We cannot find your discord account! Please try again", {
          type: "error",
        });
      }
      return message;
    },
  });
};

export const useUnregisterDiscord = () => {
  const unregisterDiscord = async () => {
    await httpClient.get("/discord/unregister-discord", {
      withCredentials: true,
    });
  };
  return useMutation(["GET /discord/unregister-discord"], unregisterDiscord, {
    onError({
      data: { message },
    }: {
      data: {
        message?: unregisterDiscordErrorCode;
      };
    }) {
      if (!message) {
        toast("Unexpected error! Please try again", {
          type: "error",
        });
      } else {
        toast("We cannot find your discord account! Please try again", {
          type: "error",
        });
      }
      return message;
    },
  });
};
