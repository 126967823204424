import { Form, Formik } from "formik";
import { FunctionComponent } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useCurrentUser } from "../../../../../../../../contexts/CurrentUser";
import { Input } from "../../../../../../../../components/input";
import { messages } from "./messages";
import { Button } from "../../../../../../../../components/button";
import { useChangeDisplayName } from "./useChangeDisplayName";
import { toast } from "react-toastify";
import { ChangeDisplayNameValidationSchema } from "./changeDisplayNameValidationSchema";

export type ChangeDisplayNameFormProps = {
  onSuccess: () => void;
};

export const ChangeDisplayNameForm: FunctionComponent<
  ChangeDisplayNameFormProps
> = ({ onSuccess }) => {
  const intl = useIntl();

  const { user, refresh } = useCurrentUser();

  const { mutateAsync, isLoading } = useChangeDisplayName();

  const onSubmit = async (values: { displayName: string }) => {
    await mutateAsync(values.displayName);
    refresh();
    toast(intl.formatMessage(messages.success), { type: "success" });
    onSuccess();
  };

  return (
    <Formik
      initialValues={{
        displayName: user?.displayName || "",
      }}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={ChangeDisplayNameValidationSchema(intl)}
      onSubmit={onSubmit}
    >
      {({ errors, handleSubmit, isSubmitting }) => (
        <Form onSubmit={handleSubmit}>
          <div className="mt-2 mb-2">
            <Input
              label={intl.formatMessage(messages.fieldDisplayNameLabel)}
              fieldAttributes={{
                name: "displayName",
                type: "text",
              }}
              error={errors.displayName}
            />
          </div>
          <Button type="submit" isLoading={isSubmitting || isLoading}>
            <FormattedMessage {...messages.submitText} />
          </Button>
        </Form>
      )}
    </Formik>
  );
};
