import httpClient from "../config/interceptors/http.interceptor";
import { UserLogin } from "../models/UserLogin.interface";
import { UserRegister } from "../models/UserRegister.interface";
import { CurrentUser } from "../types";
import { LoginApiResponse } from "../types/LoginApiResponse";
import { RegisterApiResponse } from "../types/RegisterApiResponse ";
import { LocalStorageUtil } from "../utils/localstorage.util";

export class UserService {
  register(user: UserRegister): Promise<RegisterApiResponse> {
    return httpClient.post("/user/register", user);
  }

  login(user: UserLogin): Promise<LoginApiResponse> {
    return httpClient.post("/auth/login", user);
  }

  verifyAccount(data) {
    return httpClient.patch(`/user/verify-account`, data);
  }

  getCurrentUser() {
    const sessionTicket = LocalStorageUtil.getItem("x-session-ticket");
    const playfabId = LocalStorageUtil.getItem("x-playfabid");

    if (sessionTicket === null || playfabId === null) {
      return null;
    }

    if (sessionTicket && playfabId) {
      return httpClient.get<CurrentUser>("/user/current-user");
    }

    return null;
  }
}
