import React from "react";
import { Outlet } from "react-router-dom";
import { IntlProvider } from "react-intl";
import en from "../assets/i18n/en-US.json";
import NavBar from "./NavBar";
import Footer from "./Footer";
import { useCurrentUser } from "../contexts/CurrentUser";
import { FullScreenLoader } from "./fullScreenLoader";

function Layout() {
  const { isLoading } = useCurrentUser();

  return (
    <IntlProvider locale="en" messages={en}>
      <div className="flex flex-col h-screen">
        <NavBar />
        {isLoading && <FullScreenLoader />}
        {!isLoading && <Outlet />}
        <Footer />
      </div>
    </IntlProvider>
  );
}

export default Layout;
