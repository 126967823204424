import clsx from "clsx";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Loader } from "../../../../../components/loader";
import {
  useRefetchGetDiscordInfo,
  useUnregisterDiscord,
} from "../../../../../hooks/discord/useDiscord";
import { DiscordUser } from "../../../../../types";
import "./temp_style.scss";
import Avatar from "../../../../../assets/images/discordAvatar.png";

export type DiscordConnectedUserProps = {
  discordUser: DiscordUser;
};

export const DiscordConnectedUser = ({
  discordUser,
}: DiscordConnectedUserProps) => {
  const { mutateAsync } = useUnregisterDiscord();
  const { refetch } = useRefetchGetDiscordInfo();

  let avatarUrl = "";
  if (discordUser.avatar) {
    avatarUrl = `https://cdn.discordapp.com/avatars/${discordUser.id}/${discordUser.avatar}.png`;
  }
  const [isLoggingOut, setIsLoggingOut] = useState<boolean>(false);
  const navigate = useNavigate();

  const openDiscord = () => {
    window.open("https://www.discord.com/app", "_blank");
  };
  const handleResetUserData = async () => {
    try {
      setIsLoggingOut(true);
      await mutateAsync();
      navigate("/account/discord-account/", { replace: true });
      await refetch();
      setIsLoggingOut(false);
    } catch (_err) {
      //
    }
  };

  if (!discordUser) {
    return null;
  }

  return (
    <div
      className={clsx(
        "text-white rounded-2xl border border-solid border-yellow-400",
        "ml-8 bg-black bg-opacity-70 w-custom h-custom",
        ""
      )}
    >
      {" "}
      <div className="w-full h-full">
        <div className="w-full p-5 border-b border-solid">
          <h4 className="font-bold text-base">Discord</h4>
          <span className="text-xs opacity-60 mt-4">
            More feature on linking account with discord !
          </span>
        </div>
        <div className="discord-account-link">
          <div className="account-profile-info border border-solid border-yellow-400 rounded-2xl">
            <div className="user-profile border border-solid border-yellow-400">
              <img
                src={avatarUrl === "" ? Avatar : avatarUrl}
                alt={`${discordUser.username}'s Avatar`}
              />
            </div>
            <span className="profile-global-name">
              {discordUser.globalName}
            </span>
            <span className="profile-username">{discordUser.username}</span>
          </div>
          <div>
            <button
              className="disconnect-btn bg-yellow-600 rounded-2xl"
              onClick={openDiscord}
            >
              <i className="fab fa-discord"></i> Open discord
            </button>
            <button
              onClick={handleResetUserData}
              className="disconnect-btn border border-solid border-yellow-600 rounded-2xl"
            >
              {isLoggingOut ? (
                <>
                  <Loader /> disconnecting
                </>
              ) : (
                <>
                  <i className="fa fa-door-open"></i> Disconnect
                </>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
