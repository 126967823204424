import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

import PageHeaderImg from "../../components/PageHeaderImg";

import letterBackgroundWebp from "../../assets/images/letterFAQBackground.webp";
import letterBackgroundImg from "../../assets/images/letterFAQBackground.png";
import headerScanLines from "../../assets/images/scanlines.png";

const discordRules = [
  {
    id: 1,
    ruleName: "Rude, Disrespectful Comments, Harassment",
    ruleText:
      "Comments that are demeaning towards another user or group of users are unacceptable. Harassment includes, but is not limited to, personal insults and threats, racial and religious epithets, sexual orientation, real-life information and threats, and insults based on nationality. It is in your best interest to remain constructive and polite in your posts.",
  },
  {
    id: 2,
    ruleName: "Flamebaiting / Trolling",
    ruleText:
      "Trolling is defined as purposefully antagonizing another user in hopes of causing arguments or controversy. We do not allow trolling on our Discord server and any posts that are deemed as trolling will be removed. All users have the right to report and ignore trolls. Please resist from feeding the trolls as it can be harmful to you.",
  },
  {
    id: 3,
    ruleName: "SPAM",
    ruleText:
      "SPAM is defined as Stupid Pointless Annoying Material/Message. The re-posting of deleted content and repetitive posts that serve no purpose in the discussion all fall under this category. Any user who creates a post that is considered pointless or off topic will have their post deleted. Repeat violators of this rule can have their posting abilities revoked or even a ban from the Discord.",
  },
  {
    id: 4,
    ruleName: "Staff Harassment",
    ruleText:
      "Comments that are demeaning towards any of the Mankind Reborn staff are unacceptable. Harassment includes, but is not limited to, personal insults and threats, racial and religious epithets, sexual orientation, real-life information and threats, and insults based on nationality. Constructive criticism is always acceptable and is the only way we can improve. Users are encouraged to work directly with the staff member to resolve issues or, users are encouraged to seek assistance from the team lead or administrator.",
  },
  {
    id: 5,
    ruleName: "Impersonation",
    ruleText:
      "Any user found attempting to impersonate or trying to pass themselves off as a member of the Mankind Reborn team will be issued a permanent ban from the community.",
  },
  {
    id: 6,
    ruleName: "Pornographic Material",
    ruleText:
      "Users are not allowed to post or link to anything that could be considered pornographic content. While this Discord server is adult oriented, we wish to keep them clean. The Mankind Reborn staff, at their discretion, will decide if the posted linked or content is too exposed and can be considered pornographic. We maintain a zero tolerance policy for violations of this rule and violators will be met with swift actions, including but not limited to, instant account suspension.",
  },
  {
    id: 7,
    ruleName: "Extreme or Vulgar Content",
    ruleText:
      "Users are not allowed to post or link to anything that could be considered extreme or vulgar content. The Mankind Reborn staff, at their discretion, will decide if the posted or linked content is considered extreme or vulgar. We maintain a zero tolerance policy for violations of this rule and violators will be met with swift actions, including but not limited to, instant account suspension.",
  },
  {
    id: 8,
    ruleName: "Ban Evasion",
    ruleText:
      "If you have been suspended from our system and evade by some means, this will result in an extended suspension being issued, to all suspect user accounts, up to a permanent suspension of the user continues to attempt to evade their ban.",
  },
];

function DiscordRules() {
  return (
    <motion.div
      className="flex flex-col w-full"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <header className="relative h-[250px] bg-zinc-900 border-b border-[#8ce8e8]">
        <div className="absolute inset-0">
          <PageHeaderImg
            src={letterBackgroundWebp}
            fallback={letterBackgroundImg}
            alt="Discord Rules Page Header."
            className="h-full w-full object-cover"
          />
          <div className="absolute inset-0 z-10">
            <img
              src={headerScanLines}
              alt="News Page Header Scan Lines"
              className="h-full w-full object-cover opacity-50"
            />
          </div>
        </div>
        <div className="absolute inset-0 z-20 flex items-center justify-center">
          <h1 className="font-equinox tracking-tight text-zinc-100 text-4xl md:text-5xl lg:text-6xl">
            Discord Rules
          </h1>
        </div>
      </header>
      <div className="bg-zinc-800 border-b border-[#8ce8e8]">
        <nav className="max-w-5xl mx-auto px-4 sm:px-6 xl:px-0 py-3 text-sm font-medium font-roboto-condensed text-zinc-400">
          <Link to="/" className="text-[#8ce8e8] hover:opacity-80">
            Home
          </Link>{" "}
          / Policy / Discord Rules
        </nav>
      </div>
      <main className="h-auto max-w-5xl mx-auto px-4 sm:px-6 xl:px-0 my-5 text-zinc-300 font-tajawal">
        <div className="z-40 bg-zinc-800 bg-opacity-70 drop-shadow-xl rounded-2xl px-10 py-10 text-zinc-200 border-b border-t border-[#8ce8e8]">
          <div className="font-exo-2 max-w-7xl mx-auto pt-4 sm:px-6 lg:px-8">
            <p className="mt-2 md:col-span-7">
              This list does not constitute the full set of rules, and our
              Moderators may take action on misbehavior and violations that are
              not explicitly listed below. At all times, use common sense and
              good judgement for any action you're about to take.
            </p>
          </div>
          <div className="font-exo-2 max-w-7xl mx-auto pt-6 sm:px-6 lg:px-8">
            <h2 className="text-2xl tracking-tight font-bold text-zinc-100">
              Discord Community Guidelines
            </h2>
            <p className="mt-2 md:col-span-7">
              Our server follows and enforces the{" "}
              <a
                href="https://discord.com/guidelines"
                className="transition-all ease-in text-[#8ce8e8] hover:opacity-80 cursor-alias"
              >
                Discord Community Guidelines
              </a>
              .
            </p>
          </div>
          <div className="font-exo-2 max-w-7xl mx-auto pt-6 sm:px-6 lg:px-8">
            <h2 className="text-2xl tracking-tight font-bold text-zinc-100">
              Report violations, do not engage!
            </h2>
            <p className="mt-2 md:col-span-7">
              Users are encouraged to report others to a Community Moderator or
              Far Frontier Studios staff member in order for us to review the
              engagement and moderate accordingly. Do not engage with those who
              may be violating the rules as this only compounds the potential
              issue and, in some cases, only fuel the negative engagement which
              is what some bad actors want.
            </p>
            <p className="mt-2 md:col-span-7">
              Please DM us with your reports, providing links to the exact
              message when possible, or provide any other support evidence you
              think may assist. Please note in general screenshots of something,
              while accepted, may not be used as such a thing can be easily
              doctored.
            </p>
            <p className="mt-2 md:col-span-7">
              You may also email us directly for assistance at:
              <br />
              <br />
              support(at)mankindreborn.com
              <br />
              <br />
              <i>
                Please ensure you provide your Discord Username and Game Name
                (Server) so we can link up who we are speaking with!
              </i>
            </p>
          </div>
          <div className="font-exo-2 max-w-7xl mx-auto divide-y divide-[#8ce8e8] pt-6 sm:px-6 lg:px-8">
            <h2 className="text-2xl tracking-tight font-bold text-zinc-100">
              General Rules
            </h2>
            <div className="mt-8">
              <dl className="divide-y divide-[#8ce8e8]">
                {discordRules.map((discordRule) => (
                  <div
                    key={discordRule.id}
                    className="pt-6 pb-8 md:grid md:grid-cols-12 md:gap-8"
                  >
                    <dt className="text-base font-medium text-[#f4a412] md:col-span-5">
                      {discordRule.ruleName}
                    </dt>
                    <dd className="mt-2 md:mt-0 md:col-span-7">
                      <p className="text-base text-zinc-200">
                        {discordRule.ruleText}
                      </p>
                    </dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </div>
      </main>
    </motion.div>
  );
}

export default DiscordRules;
