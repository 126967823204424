import axios from "axios";

export const getPage = async (pageSlug: string) => {
  const url = `${process.env.REACT_APP_VITE_CMS_API}/api/pages?populate=*&filters[slug][$eq]=${pageSlug}`;

  return axios
    .get(url, {
      validateStatus(status) {
        return status < 400;
      },
    })
    .then((res) => {
      return res.data.data;
    });
};

export const getRecentArticles = async () => {
  const url = `${process.env.REACT_APP_VITE_CMS_API}/api/news-articles?populate=*&pagination[start]=0&pagination[limit]=3&sort=publishedAt%3Adesc`;

  return axios
    .get(url, {
      validateStatus(status) {
        return status < 400;
      },
    })
    .then((res) => {
      return res.data.data;
    });
};

export const getArticles = async () => {
  const url = `${process.env.REACT_APP_VITE_CMS_API}/api/news-articles?populate=*&pagination[start]=0&pagination[limit]=10&sort=publishedAt%3Adesc`;

  return axios
    .get(url, {
      validateStatus(status) {
        return status < 400;
      },
    })
    .then((res) => {
      return res.data.data;
    });
};

export const getArticle = async (articleSlug: string | undefined) => {
  const url = `${process.env.REACT_APP_VITE_CMS_API}/api/news-articles?populate=*&filters[slug][$eq]=${articleSlug}`;

  return axios
    .get(url, {
      validateStatus(status) {
        return status < 400;
      },
    })
    .then((res) => {
      return res.data.data;
    });
};
