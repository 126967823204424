import { useState } from "react";
import { Field, Form, Formik } from "formik";
import { FormattedMessage, useIntl } from "react-intl";
import * as Yup from "yup";
import { UserService } from "../../../../services/user.service";
import { Link, useNavigate } from "react-router-dom";
import { LoginSteam } from "./LoginSteam";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { routes } from "../../../../routes/routes";

function LoginForm() {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const navigate = useNavigate();

  const SignInSchema = Yup.object().shape({
    username: Yup.string()
      .matches(
        /^[a-zA-Z0-9]+$/,
        intl.formatMessage({
          id: `register.invalid_username`,
          defaultMessage: "The provided username is invalid, try again!",
        })
      )
      .min(
        3,
        intl.formatMessage({
          id: `register.username_too_short`,
          defaultMessage: "Username must be at least 3 characters long",
        })
      )
      .max(
        20,
        intl.formatMessage({
          id: `register.username_too_long`,
          defaultMessage: "Username must be less than 20 characters long",
        })
      )
      .required(
        intl.formatMessage({
          id: `register.username_required`,
          defaultMessage: "Username is required",
        })
      ),
    password: Yup.string()
      .min(
        6,
        intl.formatMessage({
          id: `register.password_too_short`,
          defaultMessage: "Password must be at least 6 characters long",
        })
      )
      .max(
        100,
        intl.formatMessage({
          id: `register.password_too_long`,
          defaultMessage: "Password must be less than 100 characters long",
        })
      )
      .required(
        intl.formatMessage({
          id: `password_required`,
          defaultMessage: "Password is required",
        })
      ),
  });
  return (
    <Formik
      initialValues={{
        username: "",
        password: "",
      }}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={SignInSchema}
      onSubmit={async (values) => {
        setErrorMessage("");
        setIsLoading(true);
        try {
          await new UserService().login(values);
          navigate(routes.ACCOUNT, { replace: true });
          setIsLoading(false);
        } catch (err) {
          setIsLoading(false);

          if (err?.data?.message === "NEEDS_VERIFICATION") {
            setErrorMessage(
              intl.formatMessage({
                id: "login.error.needs-verification",
                defaultMessage:
                  "You need to verify your email before you can log in, please check your inbox.",
                description: "login - form - error - needs verification",
              })
            );
          } else {
            setErrorMessage(
              intl.formatMessage({
                id: "login.error",
                defaultMessage: "Incorrect login or password",
              })
            );
          }
        }
      }}
    >
      {({ errors, touched }) => (
        <Form>
          {errorMessage.length > 0 && (
            <p className="text-center pb-3 error-message">{errorMessage}</p>
          )}
          <div className="flex flex-col mb-2">
            <label htmlFor="">
              <FormattedMessage id="username" defaultMessage="Username" />
            </label>

            <Field
              name="username"
              type="text"
              className="w-full bg-black text-xs p-2 rounded-lg outline-none"
            />
            {touched.username && errors.username && (
              <p className="error-message">{errors.username}</p>
            )}
          </div>
          <div className="flex flex-col mb-2">
            <label htmlFor="">
              <FormattedMessage id="password" defaultMessage="Password" />
            </label>
            <Field
              name="password"
              type="password"
              className="w-full bg-black text-xs p-2 rounded-lg outline-none"
            />
            {touched.password && errors.password && (
              <p className="error-message">{errors.password}</p>
            )}
          </div>
          <button
            type="submit"
            className="bg-[#8be6e6] w-full mt-5 p-2  mb-2 rounded-lg text-black"
            disabled={isLoading}
          >
            {isLoading ? (
              <div className="flex-shrink-0">
                <FontAwesomeIcon
                  icon={faSpinner}
                  className="h-4 w-4 text-white self-center animate-spin"
                />
              </div>
            ) : (
              <FormattedMessage id="login" defaultMessage="Login" />
            )}
          </button>
          <p className="text-center">
            <FormattedMessage id="login.or" defaultMessage="or" />
          </p>
          <LoginSteam setErrorMessage={setErrorMessage}>
            Login with Steam
          </LoginSteam>

          <div className="flex items-center flex-col mt-2">
            <p className="text-sm hover:text-[#8ce8e8]">
              <Link to="/forgot-password" className="mt-3  text-sm">
                <FormattedMessage
                  id="login.forgot_password"
                  defaultMessage="Forgot password?"
                />
              </Link>
            </p>
            <p className="text-sm hover:text-[#8ce8e8]">
              <Link to="/register">
                <FormattedMessage
                  id="login.create_new_account"
                  defaultMessage="Create new account"
                />
              </Link>
            </p>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default LoginForm;
