import { useMutation } from "@tanstack/react-query";
import httpClient from "../../../../../../../config/interceptors/http.interceptor";
import { useIntl } from "react-intl";
import { messages } from "./messages";
import { toast } from "react-toastify";

export const useSetUsernameAndPassword = () => {
  const intl = useIntl();

  return useMutation(
    ["POST /user/add-username-password"],
    async (body: { username: string; password: string; email: string }) =>
      httpClient.post("/user/add-username-password", body),
    {
      // all errors messages can be found
      // onhttps://learn.microsoft.com/en-us/rest/api/playfab/client/account-management/add-username-password?view=playfab-rest
      onError: (error: { data?: { message?: string } }) => {
        const errorMessage = error?.data?.message;

        if (errorMessage === "UsernameNotAvailable") {
          toast(
            intl.formatMessage(messages.validationFieldUsernameNotAvailable),
            {
              type: "error",
            }
          );
        } else if (errorMessage === "EmailAddressNotAvailable") {
          toast(intl.formatMessage(messages.validationFieldEmailNotAvailable), {
            type: "error",
          });
        } else if (errorMessage === "AccountAlreadyLinked") {
          toast(intl.formatMessage(messages.validationAccountAlreadyLinked), {
            type: "error",
          });
        } else {
          toast(intl.formatMessage(messages.unexpectedError), {
            type: "error",
          });
        }
      },
    }
  );
};
