import { useMutation } from "@tanstack/react-query";
import httpClient from "../../../../../../../../config/interceptors/http.interceptor";
import { useIntl } from "react-intl";
import { toast } from "react-toastify";
import { messages } from "./messages";

export const useChangeDisplayName = () => {
  const intl = useIntl();

  return useMutation(
    ["PATCH /user/change-display-name"],
    async (displayName: string) =>
      httpClient.patch("/user/change-display-name", {
        displayName,
      }),
    {
      // all errors can be found on
      // https://learn.microsoft.com/en-us/rest/api/playfab/admin/account-management/update-user-title-display-name?view=playfab-rest
      onError: (error: { data?: { message?: string } }) => {
        const errorMessage = error?.data?.message;

        if (errorMessage === "NameNotAvailable") {
          toast(intl.formatMessage(messages.nameTakenError), {
            type: "error",
          });
        } else {
          toast(intl.formatMessage(messages.unexpectedError), {
            type: "error",
          });
        }
      },
    }
  );
};
