import { Loader } from "../../../../../components/loader";

const DiscordLoader = () => {
  return (
    <div className="flex justify-center items-center flex-col text-white h-custom">
      <Loader /> <i className="fab fa-discord text-3xl"></i>
    </div>
  );
};

export default DiscordLoader;
