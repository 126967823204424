import { PageWithFullScreenBackgroundImage } from "../../../components/PageWithFullScreenBackground";
import { Card } from "../../../components/card";
import { ResetPasswordForm } from "./ResetPasswordForm";

export default function ResetPassword() {
  return (
    <PageWithFullScreenBackgroundImage>
      <Card>
        <h1 className="text-2xl font-semibold">Change password</h1>
        <ResetPasswordForm />
      </Card>
    </PageWithFullScreenBackgroundImage>
  );
}
