import { Form, Formik } from "formik";
import { FormattedMessage, useIntl } from "react-intl";
import { messages } from "./messages";
import { Input } from "../../../../../../../components/input";
import { ImportGamesparksValidationSchema } from "./ImportGamesparksValidationSchema";
import { Button } from "../../../../../../../components/button";
import { useImportGamesparks } from "./useImportGamesparks";
import { toast } from "react-toastify";
import { FunctionComponent } from "react";

export type ImportGamesparksFormProps = {
  onSuccess: () => void;
};

export const ImportGamesparksForm: FunctionComponent<
  ImportGamesparksFormProps
> = ({ onSuccess }) => {
  const intl = useIntl();

  const { isLoading, mutateAsync } = useImportGamesparks();

  const onSubmit = async (values: { email: string }) => {
    try {
      await mutateAsync(values.email);
      toast(intl.formatMessage(messages.success), { type: "success" });
      onSuccess();
    } catch (err) {}
  };

  return (
    <>
      <p className="text-xs">
        <FormattedMessage {...messages.subTitle} />
      </p>
      <Formik
        initialValues={{
          email: "",
        }}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={ImportGamesparksValidationSchema(intl)}
        onSubmit={onSubmit}
      >
        {({ errors, isSubmitting, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <div className="mt-2 mb-2">
              <Input
                label={intl.formatMessage(messages.emailLabel)}
                fieldAttributes={{
                  name: "email",
                  placeholder: intl.formatMessage(messages.emailPlaceholder),
                }}
                error={errors.email}
              />
            </div>
            <Button isLoading={isSubmitting || isLoading}>
              <FormattedMessage {...messages.submit} />
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};
