import { DashboardContentHeader } from "../../../DashboardContentHeader";

export const LinkedAccountsHeader = () => {
  return (
    <DashboardContentHeader>
      <h4 className="font-bold text-base">Linked accounts</h4>
      <span className="text-xs opacity-60 mt-4">
        You can find all of your third party linked accounts here!
      </span>
    </DashboardContentHeader>
  );
};
