import React from "react";
function DonationHistory() {
  return (
    <div className="flex justify-center items-center text-white rounded-2xl border border-solid border-yellow-400 ml-8 bg-black bg-opacity-70 w-custom h-custom">
      <h1>Donation history is working... </h1>
    </div>
  );
}

export default DonationHistory;
