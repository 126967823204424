import { useMutation } from "@tanstack/react-query";
import { useEffect } from "react";
import httpClient from "../../config/interceptors/http.interceptor";

export const useSteam = ({
  onSuccess,
  onError,
}: {
  onSuccess?: (steamId: string) => void;
  onError?: (error?: string) => void;
}) => {
  useEffect(() => {
    window.addEventListener("message", (event) => {
      const { origin, data } = event;

      if (origin === process.env.REACT_APP_VITE_BACKEND_BASE) {
        if (data.steamId && onSuccess) {
          onSuccess(data.steamId);
        }
        /* 
        else if (onError) {
          onError(data);
        }
        if (data?.data?.PlayFabId && onSuccess) {
          onSuccess(data.data.PlayFabId);
        } else if (
          data?.response?.error === "AccountNotFound" &&
          data.steamId &&
          onError
        ) {
          onError("AccountNotFound", data.steamId);
        } else if (onError) {
          onError("Unexpected");
        }
        */
      }
    });
  }, []);

  const login = () => {
    const popupWindow = window.open(
      `${process.env.REACT_APP_VITE_BACKEND_API}steam/auth/return`,
      "_blank",
      "width=800, height=600"
    );
    if (typeof popupWindow?.focus === "function") popupWindow.focus();
  };

  return {
    login,
  };
};

export const useLinkSteam = () => {
  const endpoint = "/steam/link";

  return useMutation([`PATCH ${endpoint}`], (steamId: string) =>
    httpClient.patch(endpoint, { steamId })
  );
};

export const usePlayFabLoginWithSteam = () => {
  const endpoint = "/steam/login";

  return useMutation([`POST ${endpoint}`], (steamId: string) =>
    httpClient.post(endpoint, { steamId })
  );
};
