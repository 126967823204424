import { useSearchParams } from "react-router-dom";
import { useRedirection } from "../../../hooks";
import { useConfirmGamesparksDonationLink } from "./hooks/useConfirmGamesparksDonationLink";
import { FullScreenLoader } from "../../../components/fullScreenLoader";

export const GamesparksDonationLinkConfirm = () => {
  const [searchParams] = useSearchParams();
  const { redirectToAccount } = useRedirection();

  const token = searchParams.get("token");

  if (!token) {
    redirectToAccount();
  } else {
    useConfirmGamesparksDonationLink({
      token,
      onSuccess: () => {
        redirectToAccount();
      },
    });
  }

  return <FullScreenLoader />;
};
