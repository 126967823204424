import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";

import httpClient from "../../config/interceptors/http.interceptor";
import { ForgotPasswordErrorCodes } from "./types";

export const useForgotPassword = () => {
  const postForgotPassword = async ({ email }: { email: string }) => {
    await httpClient.post("/user/password-recovery-email", {
      email,
    });
  };

  return useMutation(
    ["POST /user/password-recovery-email"],
    postForgotPassword,
    {
      onError({
        data: { message },
      }: {
        data: {
          message?: ForgotPasswordErrorCodes;
        };
      }) {
        if (!message) {
          toast("Unexpected error! Please check your email and try again", {
            type: "error",
          });
        } else {
          toast(
            "We cannot find your account! Please check your email and try again",
            {
              type: "error",
            }
          );
        }
        return message;
      },
    }
  );
};

export const useResetPassword = () => {
  const resetPassword = async ({
    password,
    confirmPassword,
    token,
  }: {
    password: string;
    confirmPassword: string;
    token: string;
  }) => {
    await httpClient.post("/user/reset-password", {
      confirmPassword,
      password,
      token,
    });
  };

  return useMutation(["POST /user/reset-password"], resetPassword, {
    onError() {
      toast("Your reset password link is expired", {
        type: "error",
      });
    },
  });
};
