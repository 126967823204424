import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ScrollToTop from "./helpers/ScrollToTop";
import AnimatedRoutes from "./components/AnimatedRoutes";
import { CurrentUserContextProvider } from "./contexts/CurrentUser";

const queryClient = new QueryClient();

function App() {
  return (
    <CookiesProvider>
      <QueryClientProvider client={queryClient}>
        <Router>
          <ScrollToTop />

          <CurrentUserContextProvider>
            <AnimatedRoutes />
          </CurrentUserContextProvider>
        </Router>
        <ToastContainer position="top-right" theme="dark" />
      </QueryClientProvider>
    </CookiesProvider>
  );
}

export default App;
