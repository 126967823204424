import { redirect, useSearchParams } from "react-router-dom";
import { useVerifyEmail } from "./hooks/useVerifyEmail";
import { toast } from "react-toastify";
import { routes } from "../../routes/routes";
import { FullScreenLoader } from "../../components/fullScreenLoader";
import { useRedirection } from "../../hooks";

export const VerifyEmail = () => {
  const [searchParams] = useSearchParams();
  const { redirectToLogin } = useRedirection();

  const token = searchParams.get("token");
  const userId = searchParams.get("userId");

  if (!token || !userId) {
    redirect(routes.LOGIN);
  } else {
    useVerifyEmail({
      token,
      userId,
      onSuccess: () => {
        toast("You email has been verified, you can log in now", {
          type: "success",
        });
        redirectToLogin();
      },
    });
  }

  return <FullScreenLoader />;
};
