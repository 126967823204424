import React, { FunctionComponent } from "react";
import clsx from "clsx";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export type LoaderProps = {
  className?: string;
};

export const Loader: FunctionComponent<LoaderProps> = ({ className }) => (
  <FontAwesomeIcon
    icon={faSpinner}
    className={clsx("h-4 w-4 text-white self-center animate-spin", className)}
  />
);
