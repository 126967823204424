import { Formik, Form } from "formik";
import { Input } from "../../../components/input";
import { ResetPasswordValidationSchema } from "./validation";
import { Button } from "../../../components/button";
import { Link, redirect } from "react-router-dom";
import { ArrowLeft } from "../../../components/icons";
import { routes } from "../../../routes/routes";
import { useQuery } from "../../../hooks/useQuery";
import { useResetPassword } from "../../../hooks";
import { Alert } from "../../../components/alert";

export const ResetPasswordForm = () => {
  const { mutate, isLoading, isSuccess } = useResetPassword();

  const query = useQuery();

  if (!query) {
    redirect(routes.LOGIN);
    return null;
  }

  if (query.get("errorCode")) {
    return (
      <Alert type="error" className="mt-2">
        The link you followed is expired.
      </Alert>
    );
  }

  if (isSuccess) {
    return (
      <Alert type="success">
        Your password has been changed, you can now
        <Link to={routes.LOGIN}>
          <span className="font-semibold"> log in</span>
        </Link>
        .
      </Alert>
    );
  }

  const token = query.get("token");

  return (
    <Formik
      initialValues={{
        password: "",
        confirmPassword: "",
      }}
      validationSchema={ResetPasswordValidationSchema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={({ password, confirmPassword }) => {
        if (token) {
          mutate({ password, confirmPassword, token });
        }
      }}
    >
      {({ errors }) => (
        <Form>
          <div className="mt-2 mb-2">
            <Input
              fieldAttributes={{
                name: "password",
                placeholder: "New password",
                type: "password",
              }}
              error={errors.password}
            />
          </div>
          <Input
            fieldAttributes={{
              name: "confirmPassword",
              placeholder: "Re enter your new password",
              type: "password",
            }}
            error={errors.confirmPassword}
          />
          <Button type="submit" className="mt-4" isLoading={isLoading}>
            Save password
          </Button>

          <Link to={routes.LOGIN}>
            <p className="text-sm hover:text-[#8ce8e8] flex justify-center items-center hover:opacity-100">
              <ArrowLeft className="mr-2" />
              <span> Go back to login</span>
            </p>
          </Link>
        </Form>
      )}
    </Formik>
  );
};
