import {
  faCircleCheck,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { clsx } from "clsx";
import { FunctionComponent, ReactNode } from "react";

export type AlertProps = {
  type: "success" | "error";
  children?: ReactNode;
  className?: string;
};

export const Alert: FunctionComponent<AlertProps> = ({
  children,
  type = "success",
  className,
}) => {
  let icon = faCircleCheck;

  switch (type) {
    case "error":
      icon = faTriangleExclamation;
      break;
    default:
      icon = faCircleCheck;
      break;
  }

  return (
    <p
      className={clsx(className, {
        "text-green-500": type === "success",
        "text-red-600": type === "error",
      })}
    >
      <FontAwesomeIcon icon={icon} className="mr-2" />
      {children}
    </p>
  );
};
