import { FunctionComponent, useMemo } from "react";
import { Entitlement } from "../../../../../../types";

export type EntitlementImageProps = React.DetailedHTMLProps<
  React.ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
> & {
  entitlement: Entitlement;
};

// TO DO: handle space from name(space should be converted to '_')
export const EntitlementImage: FunctionComponent<EntitlementImageProps> = ({
  entitlement,
  ...props
}) => {
  const imageName = useMemo(() => {
    // Entitlement id is something like Entitlement.Account.Premium
    const arr = entitlement.id.split(".");

    if (arr.length > 1) {
      // The image name will be Entitlement_Account_Premium.png where 1 the count
      return arr.join("_");
    }
    return "Default";
  }, [entitlement.id, entitlement.count]);

  return (
    <img
      {...props}
      src={`${process.env.REACT_APP_S3_SERVER_URL}Entitlements/${imageName}.png`}
      alt={entitlement.name}
    />
  );
};
