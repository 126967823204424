import { NavLink } from "react-router-dom";
import "./style.scss";
import { dashboardMenuItems } from "./data";

function Menu() {
  return (
    <nav className="account-nav flex flex-col">
      {dashboardMenuItems.map((item, index) => (
        <NavLink
          className="account-nav-item m-2 py-3 px-4 rounded-lg text-sm text-white tracking"
          key={"nav-dashboard-" + index}
          to={item.path}
        >
          {item.icon} {item.name}
        </NavLink>
      ))}
    </nav>
  );
}

export default Menu;
