import clsx from "clsx";
import { FunctionComponent } from "react";

export type LinkProps = React.DetailedHTMLProps<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
> & {
  className?: string;
};

export const Link: FunctionComponent<LinkProps> = ({
  children,
  className = "",
  ...props
}) => {
  return (
    <a className={clsx("hover:text-[#8ce8e8]", className)} {...props}>
      {children}
    </a>
  );
};
