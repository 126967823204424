import clsx from "clsx";
import { FunctionComponent, PropsWithChildren } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import { useLinkSteam, useSteam } from "../../../../../../../hooks/steam";
import { toast } from "react-toastify";
import { useCurrentUser } from "../../../../../../../contexts/CurrentUser";
import { Loader } from "../../../../../../../components/loader";

export const LinkSteam: FunctionComponent<PropsWithChildren> = () => {
  const { refresh } = useCurrentUser();

  const { mutateAsync: mutateLinkSteam, isLoading } = useLinkSteam();

  const { login: loginSteam } = useSteam({
    onSuccess: async (steamId) => {
      try {
        await mutateLinkSteam(steamId);
        toast("Steam account linked successfully", { type: "success" });
        refresh();
      } catch (err) {
        const errorMessage = err.data?.message;
        // see possible error from
        // https://learn.microsoft.com/en-us/rest/api/playfab/server/account-management/link-steam-id?view=playfab-rest
        if (
          errorMessage &&
          ["LinkedAccountAlreadyClaimed", "AccountAlreadyLinked"].includes(
            errorMessage
          )
        ) {
          toast("This steam account is already used", { type: "error" });
        }
      }
    },
    onError: async (_errorMessage) => {
      toast("Something went wrong, please try again!", { type: "error" });
    },
  });

  return (
    <div className="flex  items-center">
      <button
        onClick={loginSteam}
        type="button"
        className={clsx("shadow-sm mt-2 mb-2")}
      >
        {!isLoading && <FontAwesomeIcon icon={faPlus} className="mr-1" />}
        {isLoading && <Loader className="mr-1" />}
        Link steam
      </button>
    </div>
  );
};
