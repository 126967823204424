import clsx from "clsx";
import { FunctionComponent, ReactNode } from "react";
import { Loader } from "../loader";

export type ButtonProps = {
  children?: ReactNode;
  className?: string;
  type?: "button" | "submit";
  isLoading?: boolean;
};

export const Button: FunctionComponent<ButtonProps> = ({
  children,
  className,
  type = "submit",
  isLoading,
}) => {
  return (
    <button
      type={type}
      className={clsx(
        "bg-[#8be6e6] w-full p-2 rounded-lg text-black mt-2",
        className
      )}
    >
      {!isLoading && children}
      {isLoading && <Loader />}
    </button>
  );
};
