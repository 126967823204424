import { useState } from "react";
import { ButtonYellow } from "../../../../../components/button";
import { useCurrentUserName } from "../../../../../hooks";
import { SetUsernameModal } from "./SetUsernameModal";

export const Username = () => {
  const username = useCurrentUserName();

  const [showSetUsernameModal, setShowUsernameModal] = useState(false);

  return (
    <>
      <div>
        <h4 className="font-bold text-base">Username</h4>
        <div className="text-sm mt-1">
          {!username && (
            <ButtonYellow onClick={() => setShowUsernameModal(true)}>
              Set username
            </ButtonYellow>
          )}
          {username && <span className="opacity-60">{username}</span>}
        </div>
      </div>
      {showSetUsernameModal && (
        <SetUsernameModal onDismiss={() => setShowUsernameModal(false)} />
      )}
    </>
  );
};
