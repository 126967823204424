import { Fragment } from "react";
import { Link } from "react-router-dom";
import { Menu, Popover, Transition } from "@headlessui/react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faChevronDown,
  faCircle,
  faUserCircle,
  faPersonCircleXmark,
} from "@fortawesome/free-solid-svg-icons";

import { FormattedMessage } from "react-intl";

import navBarLogoImg from "../assets/images/mrLogoBasicWhite.png";
import navBarLogoWebp from "../assets/images/mrLogoBasicWhite.webp";
import socialTwitter from "../assets/images/Twitter.svg";
import socialFacebook from "../assets/images/Facebook.svg";

import { useCurrentUser } from "../contexts/CurrentUser";
import { useLogout } from "../hooks";
import { useCurrentUserName } from "../hooks/users/useCurrentUserName";

const communityLinks = [
  {
    name: "Forums",
    description:
      "Visit the real game, Forums of Mankind Reborn, and engage with your fellow Citizens!",
    href: "https://forums.mankindreborn.com",
  },
  {
    name: "Discord",
    description:
      "Hop over to our Official Discord server for the latest news and gossip from around Union City.",
    href: "https://discord.gg/MankindReborn",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function NavBar() {
  const { user, logout: logoutContext } = useCurrentUser();

  const { mutateAsync: logout } = useLogout();

  const username = useCurrentUserName();

  async function handleLogout() {
    await logout();
    if (logoutContext) {
      logoutContext();
    }
  }
  return (
    <header className="relative bg-zinc-900 border-b border-[#8ce8e8]">
      <Popover className="relative">
        <nav className="flex items-center justify-between px-4 py-3 sm:px-6 md:justify-start md:space-x-10">
          <div>
            <Link to="/">
              <span className="sr-only">Mankind Reborn</span>
              <picture>
                <source srcSet={navBarLogoWebp} type="image/webp" />
                <img
                  src={navBarLogoImg}
                  alt="Mankind Reborn - Home Link"
                  className="aspect-auto h-10 w-10"
                />
              </picture>
            </Link>
          </div>
          <div className="-my-2 -mr-2 md:hidden">
            <Popover.Button className="inline-flex items-center justify-center rounded-md bg-zinc-700 p-2 text-gray-400 hover:text-[#8ce8e8] focus:outline-none focus:ring-2 focus:ring-inset focus:ring-[#8ce8e8]">
              <span className="sr-only">
                <FormattedMessage
                  id="header.open_menu"
                  defaultMessage="Open menu"
                />
              </span>
              <FontAwesomeIcon
                icon={faBars}
                className="-h-6 w-6"
                aria-hidden="true"
              />
            </Popover.Button>
          </div>
          <div className="hidden md:flex md:flex-1 md:items-center md:justify-between">
            <Popover.Group as="nav" className="flex space-x-10">
              <Link
                to="/news"
                className="font-exo-2 text-md text-white hover:text-[#8ce8e8]"
              >
                News
              </Link>
              <Popover className="relative">
                {({ open }) => (
                  <>
                    <Popover.Button
                      className={classNames(
                        open ? "text-[#8ce8e8]" : "text-white",
                        "group inline-flex items-center font-exo-2 text-md text-white hover:text-[#8ce8e8]"
                      )}
                    >
                      <span>
                        <FormattedMessage
                          id="header.community"
                          defaultMessage="Community"
                        />
                      </span>
                      <FontAwesomeIcon
                        icon={faChevronDown}
                        className={classNames(
                          open ? "text-[#8ce8e8]" : "text-white",
                          "ml-2 h-4 w-4 group-hover:text-[#8ce8e8]"
                        )}
                        aria-hidden="true"
                      />
                    </Popover.Button>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <Popover.Panel className="absolute left-1/2 z-10 mt-4 ml-5 w-screen max-w-xs -translate-x-1/2 transform px-2 sm:px-0 rounded-lg border-b border-[#8ce8e8]">
                        <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                          <div className="relative grid gap-6 bg-zinc-800 bg-opacity-90 px- 5 py-6 sm:gap-8 sm:p-8">
                            {communityLinks.map((item) => (
                              <a
                                key={item.name}
                                href={item.href}
                                target="_blank"
                                className="-m-3 block rounded-md p-3 text-white hover:text-[#8ce8e8] cursor-alias"
                                rel="noreferrer"
                              >
                                {item.name === "Forums" ? (
                                  <>
                                    <p className="font-medium">
                                      <FormattedMessage
                                        id="header.forums"
                                        defaultMessage="Forums"
                                      />
                                    </p>
                                    <p className="mt-1 text-sm">
                                      <FormattedMessage
                                        id="header.visit"
                                        defaultMessage={item.description}
                                      />
                                    </p>
                                  </>
                                ) : (
                                  <>
                                    <p className="font-medium">
                                      <FormattedMessage
                                        id="header.discord"
                                        defaultMessage="Forums"
                                      />
                                    </p>
                                    <p className="mt-1 text-sm">
                                      <FormattedMessage
                                        id="header.join_discord"
                                        defaultMessage={item.description}
                                      />
                                    </p>
                                  </>
                                )}
                              </a>
                            ))}
                          </div>
                          <div className="bg-zinc-800 bg-opacity-90 px-5 pb-5 sm:px-8 sm:pb-8">
                            <div className="flex flex-row space-x-5 justify-center">
                              <a
                                href="https://twitter.com/Mankind_Reborn"
                                target="_blank"
                                className="transition-all ease-in hover:opacity-80 cursor-alias"
                                rel="noreferrer"
                              >
                                <img
                                  className="w-9"
                                  src={socialTwitter}
                                  alt="Twitter"
                                />
                              </a>
                              <a
                                href="https://www.facebook.com/MankindReborn/"
                                target="_blank"
                                className="transition-all ease-in hover:opacity-80 cursor-alias"
                                rel="noreferrer"
                              >
                                <img
                                  className="w-9"
                                  src={socialFacebook}
                                  alt="Facebook"
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>
            </Popover.Group>
            <div className="flex items-center md:ml-12">
              {user ? (
                <Menu as="div" className="relative inline-block text-left">
                  <div>
                    <Menu.Button className="inline-flex items-center font-exo-2 text-md text-white hover:text-[#8ce8e8]">
                      <FormattedMessage
                        id="header.account"
                        defaultMessage="Account"
                      />
                      <FontAwesomeIcon
                        icon={faChevronDown}
                        className="ml-2 h-4 w-4"
                        aria-hidden="true"
                      />
                    </Menu.Button>
                    {/* {isLoggingOut ? (
                      <span className="inline-flex items-center font-exo-2 text-md text-zinc-400 animate-pulse select-none">
                        <FormattedMessage
                          id="header.logging_out"
                          defaultMessage="Logging out..."
                        />
                        <FontAwesomeIcon
                          icon={faSpinner}
                          className="ml-2 h-4 w-4 animate-spin animate-pulse text-[#8ce8e8]"
                          aria-hidden="true"
                        />
                      </span>
                    ) : (
                      <Menu.Button className="inline-flex items-center font-exo-2 text-md text-white hover:text-[#8ce8e8]">
                        <FormattedMessage
                          id="header.account"
                          defaultMessage="Account"
                        />
                        <FontAwesomeIcon
                          icon={faChevronDown}
                          className="ml-2 h-4 w-4"
                          aria-hidden="true"
                        />
                      </Menu.Button>
                    )} */}
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items
                      style={{
                        zIndex: 1000,
                      }}
                      className="absolute right-0 z-[1000] mt-2 w-56 origin-top-right bg-zinc-800 bg-opacity-90 divide-y divide-zinc-500 rounded-lg border-b border-[#8ce8e8] bg-zinc-800 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                    >
                      <div className="px-4 py-3 font-exo-2 grid grid-rows-2 grid-flow-col gap-0.5 items-center">
                        <FontAwesomeIcon
                          icon={faUserCircle}
                          className="w-8 h-8 mr-1 row-span-2 text-[#8ce8e8]"
                        />
                        <p className="text-sm text-zinc-300 col-span-10">
                          <FormattedMessage
                            id="header.signed_in_as"
                            defaultMessage="Signed is as"
                          />
                        </p>
                        {username && (
                          <p className="truncate text-sm font-medium text-zinc-100">
                            {username}
                          </p>
                        )}
                      </div>
                      <div className="py-1">
                        <Menu.Item>
                          <Link
                            to="/account"
                            className="text-white flex items-center hover:text-[#8ce8e8] block px-4 py-2 text-sm"
                          >
                            <FontAwesomeIcon
                              icon={faCircle}
                              className="w-1.5 h-1.5 mr-2"
                            />
                            <FormattedMessage
                              id="header.account_dash"
                              defaultMessage="Account Dashboard"
                            />
                          </Link>
                        </Menu.Item>
                      </div>
                      <div className="py-1">
                        <Menu.Item>
                          <button
                            type="button"
                            onClick={handleLogout}
                            className="text-white cursor-pointer hover:text-[#8ce8e8] block w-full px-4 py-2 text-left text-sm flex items-center"
                          >
                            <FontAwesomeIcon
                              icon={faPersonCircleXmark}
                              className="w-4 h-4 mr-2"
                            />
                            <FormattedMessage
                              id="header.sign_out"
                              defaultMessage="Sign out"
                            />{" "}
                            ?
                          </button>
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              ) : (
                <Link
                  to="/login"
                  className="font-exo-2 text-md text-white hover:text-[#8ce8e8]"
                >
                  <FormattedMessage
                    id="header.login_register"
                    defaultMessage="Login / Register"
                  />
                </Link>
              )}
            </div>
          </div>
        </nav>
      </Popover>
    </header>
  );
}

export default NavBar;
