import { FunctionComponent, ReactNode } from "react";

import { DialogContent, DialogOverlay } from "@reach/dialog";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";
export type ModalProps = {
  children: ReactNode;
  closable?: boolean;
  onDismiss?: () => void;
  title: ReactNode | string;
};

export const Modal: FunctionComponent<ModalProps> = (props) => {
  const { children, closable, title, onDismiss } = props;

  return (
    <DialogOverlay isOpen onDismiss={closable ? onDismiss : undefined}>
      <div
        className={clsx(
          "fixed flex right-0 left-0 justify-center h-screen w-screen",
          "py-16 overflow-y-auto justify-center items-start"
        )}
        style={{
          top: 0,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        }}
      >
        <DialogContent
          className={clsx(
            "sm:w-full lg:w-1/2 rounded-2xl h-auto flex flex-col",
            "bg-black bg-opacity-90",
            "py-3 px-4",
            "border-t border-b border-solid border-[#8ce8e8]"
          )}
        >
          <div className="flex text-white justify-between mb-2">
            <h1 className="text-2xl font-semibold">{title}</h1>
            {closable && (
              <button type="button" onClick={onDismiss}>
                <FontAwesomeIcon className="text-xl" icon={faXmark} />
              </button>
            )}
          </div>
          {children}
        </DialogContent>
      </div>
    </DialogOverlay>
  );
};
