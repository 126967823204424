import { useMutation } from "@tanstack/react-query";
import httpClient from "../../config/interceptors/http.interceptor";
import { CurrentUser } from "../../types";

export const useGetCurrentUser = () => {
  const fetchCurrentUser = async () => {
    const { data } = await httpClient.get<CurrentUser>("/user/current");

    return data;
  };

  return useMutation(["GET /users/current"], fetchCurrentUser);
};
