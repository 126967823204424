import clsx from "clsx";
import { FunctionComponent } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

export type PaginationLinksProps = {
  currentPage: number;
  totalPages: number;
  setCurrentPage: (page: number) => void;
};

export const PaginationLinks: FunctionComponent<PaginationLinksProps> = ({
  currentPage,
  totalPages,
  setCurrentPage,
}) => {
  const pages = Array.from(Array(totalPages).keys());

  if (totalPages === 1) {
    return null;
  }

  return (
    <div className="flex">
      {currentPage - 1 > 0 && (
        <button className="mr-1">
          <FontAwesomeIcon
            icon={faChevronLeft}
            onClick={() => setCurrentPage(currentPage - 1)}
          />
        </button>
      )}
      {pages.map((_, index) => (
        <button
          className={clsx("mr-1", {
            "text-zinc-300": index + 1 !== currentPage,
          })}
          key={index}
          onClick={() => setCurrentPage(index + 1)}
        >
          {index + 1}
        </button>
      ))}
      {currentPage + 1 <= totalPages && (
        <button className="mr-1">
          <FontAwesomeIcon
            icon={faChevronRight}
            onClick={() => setCurrentPage(currentPage + 1)}
          />
        </button>
      )}
    </div>
  );
};
