import React from "react";

export const AccountTypeSection = () => {
  return (
    <div className="info-account-type">
      <h4 className="font-bold text-base">Account Type</h4>
      <span className="text-sm opacity-60 mt-4">Player</span>
    </div>
  );
};
