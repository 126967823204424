import { LocalStorageUtil } from "../../utils/localstorage.util";

const updateHeaderInterceptor = (axiosInstance) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      config.headers["X-SessionTicket"] =
        LocalStorageUtil.getItem("x-session-ticket");
      config.headers["X-EntityToken"] =
        LocalStorageUtil.getItem("x-enity-token");
      config.headers["X-PlayFabId"] = LocalStorageUtil.getItem("x-playfabid");
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

export default updateHeaderInterceptor;
