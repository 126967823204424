import { useEffect, useState } from "react";
import "./discord-connexion.scss";
import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "../../../../../components/loader";
import {
  useRefetchGetDiscordInfo,
  useRegisterDiscord,
} from "../../../../../hooks/discord/useDiscord";
import clsx from "clsx";

export const DiscordConnexion = () => {
  const { mutate } = useRegisterDiscord();
  const { refetch: refetchDiscordInfo } = useRefetchGetDiscordInfo();

  const [requestPending, setRequesPending] = useState<boolean>(false);
  const [isLoadingT, setIsLoading] = useState<boolean>(true);
  const discordId = useParams().id;
  const navigate = useNavigate();

  const handleDiscordLink = () => {
    setRequesPending(true);
    window.open(
      process.env.REACT_APP_VITE_BACKEND_API + "discord/login",
      "_self"
    );
  };

  useEffect(() => {
    if (discordId) {
      mutate({ discordUserId: discordId });
      navigate("/account/discord-account/", { replace: true });
      // no need to wait for refetch
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      refetchDiscordInfo();
    }
    setIsLoading(false);
  }, []);

  return (
    <div
      className={clsx(
        "text-white rounded-2xl bg-opacity-70 discord-account-link"
      )}
    >
      {isLoadingT ? (
        <Loader />
      ) : (
        <div className="w-full h-full">
          <div className="w-full p-5 border-b border-solid">
            <h4 className="font-bold text-base">Discord</h4>
            <span className="text-xs opacity-60 mt-4">
              More feature on linking account with discord !
            </span>
          </div>
          <div className="p-5">
            <p className="text-center">
              <i className="fab fa-discord" style={{ fontSize: "50px" }}></i>{" "}
            </p>
            <p className="text-center">
              Link your discord account, redeem your discord perks.
            </p>
            <div className="flex justify-center">
              <button
                className="connect-discord-button rounded"
                onClick={handleDiscordLink}
              >
                {requestPending ? (
                  <>
                    {" "}
                    <Loader /> Connecting...
                  </>
                ) : (
                  <>
                    {" "}
                    <i className="fab fa-discord"></i> Login with discord
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
