import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import moment from "moment/moment";

import { getPage, getArticles } from "../api/cms";

import PageHeaderImg from "../components/PageHeaderImg";
import Loading from "../components/Loading";
import Error503 from "./Error503";

import headerScanLines from "../assets/images/scanlines.png";
import NewsDefaultImage from "../assets/images/newsDefault.png";
import { type PageInfo } from "../models/PageInfo.interface";
import { type Article } from "../models/Article.interface";

function News() {
  const [isLoading, setIsLoading] = useState(false);
  const [loadingError, setLoadingError] = useState<boolean>(false);
  const [pageInfo, setPageInfo] = useState<PageInfo>();
  const [articles, setArticles] = useState<Article[]>([]);

  const fetchWithPromiseAll = async () => {
    setIsLoading(true);

    const getPagePromise = getPage("news");
    const getArticlesPromise = getArticles();

    const [getPageResponse, getArticlesResponse] = await Promise.all([
      getPagePromise.catch(() => {
        setLoadingError(true);
        setIsLoading(false);
        /* console.clear(); */
      }),

      getArticlesPromise.catch(() => {
        setLoadingError(true);
        setIsLoading(false);
        /* console.clear(); */
      }),
    ]);

    setPageInfo(getPageResponse[0]);
    setArticles(getArticlesResponse);

    setIsLoading(false);
  };

  useEffect(() => {
    fetchWithPromiseAll();
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  if (loadingError) {
    return <Error503 system="News" />;
  }

  return (
    <motion.div
      className="flex flex-col w-full"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="relative h-[250px] bg-zinc-900 border-b border-[#8ce8e8]">
        <div className="absolute inset-0">
          <PageHeaderImg
            src={pageInfo?.attributes?.pageHeaderWEBP.data.attributes.url}
            fallback={pageInfo?.attributes?.pageHeader.data.attributes.url}
            alt="News Page Header"
            className="h-full w-full object-cover opacity-70"
          />
          <div className="absolute inset-0 z-10">
            <img
              src={headerScanLines}
              alt="News Page Header Scan Lines"
              className="h-full w-full object-cover opacity-50"
            />
          </div>
        </div>
        <div className="absolute inset-0 z-20 flex items-center justify-center">
          <h1 className="font-equinox tracking-tight text-zinc-100 text-4xl md:text-5xl lg:text-6xl">
            {pageInfo?.attributes?.pageTitle}
          </h1>
        </div>
      </div>
      <div className="bg-zinc-800 border-b border-[#8ce8e8]">
        <nav className="max-w-5xl mx-auto px-4 sm:px-6 xl:px-0 py-3 text-sm font-medium font-roboto-condensed text-zinc-400">
          <Link to="/" className="text-zinc-300 hover:text-[#8ce8e8]">
            Home
          </Link>{" "}
          / {pageInfo?.attributes?.pageTitle}
        </nav>
      </div>
      <main className="h-auto max-w-5xl mx-auto px-4 sm:px-6 xl:px-0 my-5">
        <div className="flex flex-wrap -mx-1 overflow-hidden md:-mx-2">
          {articles
            .filter((e, i) => i === 0)
            .map((article, i) => (
              <div
                key={article.id}
                className="flex my-1 px-1 w-full overflow-hidden sm:w-full md:my-2 md:px-2 md:w-1/2 lg:w-1/2 grow"
              >
                <div className="mx-auto max-w-lg lg:max-w-full flex-1 mt-5 grid gap-5">
                  <div className="flex flex-col overflow-hidden rounded-lg shadow-lg">
                    <div className="flex-shrink-0">
                      {article.attributes.featuredImage.data ? (
                        <img
                          className="h-48 w-full object-cover"
                          src={
                            article.attributes.featuredImage.data.attributes.url
                          }
                          alt={article.attributes.title}
                        />
                      ) : (
                        <img
                          className="h-48 w-full object-cover"
                          src={NewsDefaultImage}
                          alt={article.attributes.title}
                        />
                      )}
                    </div>
                    <div className="flex-1 bg-zinc-800 p-6 flex flex-col justify-between">
                      <div className="flex-1">
                        <span
                          className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium"
                          style={{
                            backgroundColor:
                              article.attributes.news_category.data.attributes
                                .bgcolor,
                            color:
                              article.attributes.news_category.data.attributes
                                .textcolor,
                          }}
                        >
                          {
                            article.attributes.news_category.data.attributes
                              .Name
                          }
                        </span>
                        <Link
                          to={`/news/${article.attributes.slug}`}
                          className="block mt-2"
                        >
                          <p className="text-xl font-semibold text-[#8ce8e8]">
                            {article.attributes.title}
                          </p>
                          <p className="text-xs text-zinc-300">
                            <span className="font-semibold">Posted:&nbsp;</span>
                            {moment(article.attributes.updatedAt).calendar()}
                          </p>
                          <p className="mt-3 text-base text-zinc-200">
                            {article.attributes.excerpt}
                          </p>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          {articles
            .filter((e, i) => i !== 0)
            .map((article, i) => (
              <div
                key={article.id}
                className="flex my-1 px-1 w-full overflow-hidden sm:w-full md:my-2 md:px-2 md:w-1/2 lg:w-1/3"
              >
                <div className="mx-auto flex-1 mt-5 grid max-w-lg gap-5">
                  <div className="flex flex-col overflow-hidden rounded-lg shadow-lg">
                    <div className="flex-shrink-0">
                      {article.attributes.featuredImage.data ? (
                        <img
                          className="h-48 w-full object-cover"
                          src={
                            article.attributes.featuredImage.data.attributes.url
                          }
                          alt={article.attributes.title}
                        />
                      ) : (
                        <img
                          className="h-48 w-full object-cover"
                          src={NewsDefaultImage}
                          alt={article.attributes.title}
                        />
                      )}
                    </div>
                    <div className="flex-1 bg-zinc-800 p-6 flex flex-col justify-between">
                      <div className="flex-1">
                        <span
                          className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium"
                          style={{
                            backgroundColor:
                              article.attributes.news_category.data.attributes
                                .bgcolor,
                            color:
                              article.attributes.news_category.data.attributes
                                .textcolor,
                          }}
                        >
                          {
                            article.attributes.news_category.data.attributes
                              .Name
                          }
                        </span>
                        <Link
                          to={`/news/${article.attributes.slug}`}
                          className="block mt-2"
                        >
                          <p className="text-xl font-semibold text-[#8ce8e8]">
                            {article.attributes.title}
                          </p>
                          <p className="text-xs text-zinc-300">
                            <span className="font-semibold">Posted:&nbsp;</span>
                            {moment(article.attributes.updatedAt).calendar()}
                          </p>
                          <p className="mt-3 text-base text-zinc-200">
                            {article.attributes.excerpt}
                          </p>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </main>
    </motion.div>
  );
}
export default News;
