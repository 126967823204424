import axios from "axios";
import responseInterceptor from "./response.interceptor";
import updateHeaderInterceptor from "./update-header.interceptor";
import { LocalStorageUtil } from "../../utils/localstorage.util";

const httpClient = axios.create({
  // automatically redirected by proxy
  baseURL: "/api",
  headers: {
    "Content-Type": "application/json",
    "X-SessionTicket": LocalStorageUtil.getItem("x-session-ticket"),
    "X-PlayfabId": LocalStorageUtil.getItem("x-playfabid"),
  },
});

responseInterceptor(httpClient);
updateHeaderInterceptor(httpClient);

export default httpClient;
