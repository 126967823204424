import clsx from "clsx";
import { Field, FieldAttributes } from "formik";
import React, { FunctionComponent, ReactNode } from "react";

export type InputProps = {
  label?: string | ReactNode;
  fieldAttributes: FieldAttributes<unknown>;
  error?: string;
  inputClassName?: string;
};

export const Input: FunctionComponent<InputProps> = ({
  label,
  fieldAttributes,
  error,
  inputClassName = "",
}) => {
  return (
    <>
      {label && <label className="mb-1">{label}</label>}
      <Field
        className={clsx(
          "w-full bg-black text-xs p-2 rounded-lg outline-none",
          inputClassName
        )}
        {...fieldAttributes}
      />
      {error && <p className="error-message">{error}</p>}
    </>
  );
};
