/* eslint-disable */
// TO DO fix lint error of this file
import React, { ImgHTMLAttributes, useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useParams } from "react-router-dom";
import moment from "moment";
import Loading from "../components/Loading";

import { getArticle } from "../api/cms";
import Error503 from "./Error503";

import newsDefaultImagePng from "../assets/images/newsDefault.png";
import { Article } from "../models/Article.interface";

function NewsArticle() {
  const { articleSlug } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [loadingError, setLoadingError] = useState(false);
  const [article, setArticle] = useState<Article[]>([]);
  const [featuredImage, setFeaturedImage] = useState<any>();

  const fetchWithPromiseAll = async () => {
    setIsLoading(true);

    const getArticlePromise = getArticle(articleSlug);

    const [getArticleResponse] = await Promise.all([
      getArticlePromise.catch(() => {
        setLoadingError(true);
        setIsLoading(false);
        console.clear();
      }),
    ]);

    if (getArticleResponse[0]?.attributes.featuredImage.data !== null) {
      setFeaturedImage(
        getArticleResponse[0]?.attributes.featuredImage.data.attributes.url
      );
    } else {
      setFeaturedImage(newsDefaultImagePng);
    }

    setArticle(getArticleResponse);

    setIsLoading(false);
  };

  useEffect(() => {
    fetchWithPromiseAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articleSlug]);

  if (isLoading) {
    return <Loading />;
  }

  if (loadingError) {
    return <Error503 system="News" />;
  }

  return (
    <motion.div
      className="flex flex-col w-full"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <main className="min-h-screen px-4 sm:px-6 xl:px-0 my-5">
        <div className="bg-gradient-to-b from-zinc-800 rounded-t-xl max-w-5xl mx-auto pt-5">
          {featuredImage && (
            <div className="flex items-center justify-center mx-auto mb-5 max-w-sm max-h-56 md:max-h-72 lg:max-h-[310px] md:max-w-md lg:max-w-lg xl:max-w-3xl overflow-hidden">
              <img
                className="object-cover object-center"
                src={featuredImage}
                alt="Featured Media"
              />
            </div>
          )}
          <div className="max-w-5xl mx-auto p-4">
            <p className="text-4xl font-semibold text-zinc-100">
              {article[0]?.attributes.title}
            </p>
            <p className="text-sm text-zinc-400 mb-5">
              <span className="font-semibold">Posted:&nbsp;</span>
              <abbr title={article[0]?.attributes.publishedAt}>
                {moment(article[0]?.attributes.publishedAt).calendar()}
              </abbr>
            </p>
            <div
              className="prose prose-invert prose-lg max-w-5xl font-exo-2 max-w-5xl mx-auto"
              dangerouslySetInnerHTML={{
                __html: article[0]?.attributes.content,
              }}
            />
          </div>
        </div>
      </main>
    </motion.div>
  );
}

export default NewsArticle;
